/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../component/Loader';
import ModalComponent from '../component/modal/ModalComponent';
import QuestionListingComponent from '../component/QuestionComponent';
import UserBtn from '../component/UserBtn';
import { MESSAGES } from '../constants';
import { getBusinessFromToken } from '../helper/services/apis/business-services';
import { getPlaneQuestions } from '../helper/services/apis/question-services';
import {
    getSavedBusinessSurvey,
    saveSurvey,
    submitSurvey
} from '../helper/services/apis/surveys-services';
import { checkIsTokenValidForPayment } from '../helper/services/apis/user-services';
import UserLayout from '../layouts/UserLayout';

function FillSurvey() {
    const params = useParams();
    const navigate = useNavigate();

    const [token, setToken] = useState('');
    const [isLoader, setIsLoader] = useState(true);
    const [dataRows, setDataRows] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [businessId, setBusinessId] = useState(null);
    const [isSaveAnswersModalOpen, setIsSaveAnswersModalOpen] = useState(false);
    const [isSubmitAnswersModalOpen, setIsSubmitAnswersModalOpen] = useState(false);

    const sendBtnOnClickHandler = async () => {
        const payload = {
            businessId,
            surveys: dataRows
        };
        try {
            await submitSurvey(payload);
            toast.success(MESSAGES.SUCCESS);
            navigate('/complete');
        } catch (error) {
            toast.error(MESSAGES.SERVER_ERROR);
            return false;
        }
    };

    const handleSurveyInputChange = (event, index) => {
        const newRowData = [...dataRows];
        const row = { ...newRowData[index] };
        row.value = event.target.value;
        newRowData[index] = { ...row };
        setDataRows([...newRowData]);
    };

    // eslint-disable-next-line no-shadow
    const initBusinessSurvey = async (token) => {
        const result = await getSavedBusinessSurvey(token).catch((e) => {
            setDataRows([]);
        });
        setDataRows([
            ...result.data.map((item) => ({
                id: item.questionId,
                question: item.question,
                // questionType: item.answer === 'SI' || item.answer === 'NO' ? 'Boolean' : 'Numeric',
                questionType: item.questionType,
                questionPoints: item.questionPoints,
                surveyId: item.id,
                minimumPercentage: item.minimumPercentage,
                value: item.answer
            }))
        ]);
    };

    const initQuestions = async (id, categoryId) => {
        const result = await getPlaneQuestions(id, categoryId).catch((e) => {
            setDataRows([]);
        });

        setDataRows([...result.data]);
    };

    const validateToken = async (t) => {
        if (t && t.length) {
            const result = await checkIsTokenValidForPayment(t);
            if (result.data.isValid && result.data.isPayment && result.data.status) {
                const businessResult = await getBusinessFromToken(t);
                if (businessResult && businessResult.ok) {
                    if (businessResult.data.result) {
                        navigate('/');
                    } else {
                        setBusinessId(businessResult.data.id);
                        if (businessResult.data.surveyStatus === 'created') {
                            initBusinessSurvey(businessResult.data.token);
                        } else {
                            initQuestions(
                                businessResult.data.planeId,
                                businessResult.data.businessCategoryId
                            );
                        }
                    }
                }
                setIsLoader(false);
            } else {
                navigate('/');
            }
        }
    };

    const getRows = (data) => {
        return data.map((item, index) => ({
            className: '',
            cells: [
                { template: <strong>{index + 1}.</strong> },
                {
                    template: <span>{item.question}</span>
                },
                {
                    className: 'flex justify-end',
                    template:
                        item.questionType === 'Boolean' ? (
                            <div className="my-6 flex">
                                <div className="form-controller-horizontal-label mr-6">
                                    <input
                                        type="radio"
                                        value="SI"
                                        name={item.id}
                                        onChange={(event) => handleSurveyInputChange(event, index)}
                                        checked={item.value === 'SI'}
                                    />
                                    <label>SI</label>
                                </div>
                                <div className="form-controller-horizontal-label mr-6">
                                    <input
                                        type="radio"
                                        value="NO"
                                        name={item.id}
                                        onChange={(event) => handleSurveyInputChange(event, index)}
                                        checked={item.value === 'NO'}
                                    />
                                    <label>NO</label>
                                </div>
                            </div>
                        ) : (
                            <div className="form-controller-vertical-label relative w-20">
                                <input
                                    type="number"
                                    placeholder=""
                                    value={item.value}
                                    onChange={(event) => handleSurveyInputChange(event, index)}
                                />
                                <span className="absolute top-2 right-3 text-gray-400 text-base">
                                    %
                                </span>
                            </div>
                        )
                }
            ]
        }));
    };

    const updateSaveModalStatus = (status) => {
        setIsSaveAnswersModalOpen(status);
    };

    const saveOpenModalBtnOnClickHandler = () => {
        updateSaveModalStatus(true);
    };

    const saveBtnOnClickHandler = async () => {
        const payload = {
            businessId,
            surveys: dataRows
        };
        try {
            await saveSurvey(payload);
            toast.success(MESSAGES.SUCCESS);
            updateSaveModalStatus(false);
            navigate('/save-survey');
        } catch (error) {
            toast.error(MESSAGES.SERVER_ERROR);
            return false;
        }
    };

    const onSaveAnswersModalCloseBtnClickHandler = () => {
        updateSaveModalStatus(false);
    };

    useEffect(() => {
        setToken(params.token);
    }, []);

    useEffect(() => {
        validateToken(token);
    }, [token]);

    useEffect(() => {
        let result = true;
        if (dataRows.length > 0) {
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < dataRows.length; index++) {
                const element = dataRows[index];
                if (element.value === '' || element.value == null) {
                    result = false;
                    break;
                }
            }
        } else {
            result = false;
        }
        setIsValid(result);
    }, [dataRows]);

    return isLoader ? (
        <Loader />
    ) : (
        <UserLayout title="DOMANDE DEL " subtitle="QUESTIONARIO">
            <ModalComponent
                isOpen={isSaveAnswersModalOpen}
                onClose={onSaveAnswersModalCloseBtnClickHandler}>
                {/* Modal header  */}
                <div className="py-9 px-6 rounded-t border-b text-center">
                    <h1 className="text-4xl font-semibold text-primary-900">ATTENZIONE!</h1>
                </div>
                {/* Modal body  */}
                <div className="p-9">
                    <p className="mb-12 text-2xl text-center">
                        È possibile terminare il questionario in un secondo momento, ti sarà inviato
                        un link alla tua casella mail da utilizzare per completare le risposte
                        mancanti.
                    </p>

                    <p className="mb-12 text-2xl text-center">
                        <strong>Vuoi procedere ?</strong>
                    </p>
                    <div className="flex justify-center w-full">
                        <div>
                            <UserBtn
                                className="mt-6 mb-6 block mx-auto w-full"
                                onClick={saveBtnOnClickHandler}>
                                TERMINA
                            </UserBtn>
                            <UserBtn
                                className="mt-6 mb-2 block mx-auto"
                                isOutline
                                onClick={onSaveAnswersModalCloseBtnClickHandler}>
                                INDIETRO
                            </UserBtn>
                        </div>
                    </div>
                </div>
            </ModalComponent>
            <ModalComponent
                isOpen={isSubmitAnswersModalOpen}
                onClose={() => setIsSubmitAnswersModalOpen(false)}>
                {/* Modal header  */}
                <div className="py-9 px-6 rounded-t border-b text-center">
                    <h1 className="text-4xl font-semibold text-primary-900">ATTENZIONE!</h1>
                </div>
                {/* Modal body  */}
                <div className="p-9">
                    <p className="mb-12 text-2xl text-center">
                        Una volta terminato il test non è più possibile tornare indietro o rifarlo.
                        Prima di confermare assicurati di avere controllato tutte le risposte.
                    </p>

                    <p className="mb-12 text-2xl text-center">
                        <strong>Vuoi terminare il questionario?</strong>
                    </p>

                    <div className="flex justify-center w-full">
                        <div>
                            <UserBtn
                                className="mt-6 mb-6 block mx-auto w-full"
                                onClick={sendBtnOnClickHandler}>
                                TERMINA
                            </UserBtn>
                            <UserBtn
                                className="mt-6 mb-2 block mx-auto"
                                isOutline
                                onClick={() => setIsSubmitAnswersModalOpen(false)}>
                                INDIETRO
                            </UserBtn>
                        </div>
                    </div>
                </div>
            </ModalComponent>
            <section>
                <p>
                    È possibile consultare qualsiasi termine o definizione di cui non si conosce il
                    signifcato premendo qui:
                </p>
                <UserBtn className="mt-6 mb-12 block">
                    <NavLink to="/terms-and-condition">TERMINI E DEFINIZIONI</NavLink>
                </UserBtn>

                <QuestionListingComponent rows={getRows(dataRows)} />

                <div className="flex justify-center w-full">
                    <div>
                        <UserBtn
                            className="my-12 block mx-auto w-full"
                            onClick={() => setIsSubmitAnswersModalOpen(true)}
                            disable={!isValid}>
                            INVIA
                        </UserBtn>

                        <UserBtn
                            className="my-12 block mx-auto"
                            onClick={saveOpenModalBtnOnClickHandler}
                            isOutline>
                            TERMINA IN SEGUITO
                        </UserBtn>
                    </div>
                </div>
            </section>
        </UserLayout>
    );
}

export default FillSurvey;
