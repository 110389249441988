/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react';

function UserBtn({
    children,
    title,
    onClick,
    type = 'button',
    className,
    disable = false,
    isOutline = false
}) {
    useEffect(() => {
        console.log(' disable : ', disable);
    }, [disable]);

    return (
        <button
            type={type}
            title={title}
            onClick={onClick}
            className={`py-2 px-9 text-center min-w-[10rem] text-xl font-bold border-4 border-primary-900  ${
                isOutline
                    ? 'text-primary-900 hover:border-primary-800'
                    : 'bg-primary-900 text-white hover:bg-primary-800'
            } ${className}`}
            disabled={disable}>
            {children}
        </button>
    );
}

export default UserBtn;
