/* eslint-disable react/prop-types */
import React from 'react';

function UserHeader({ title, subtitle }) {
    return (
        <div className="h-48 w-full max-w-7xl mx-auto text-center flex flex-col py-10">
            <span className="text-5xl">{title}</span>
            <span className="text-5xl font-bold mt-10">{subtitle}</span>
        </div>
    );
}

export default UserHeader;
