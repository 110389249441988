/* eslint-disable react/prop-types */
import React from 'react';

function Card({ children, className, ...props }) {
    return (
        <div className={`bg-white rounded-xl drop-shadow-md ${className}`} {...props}>
            {children}
        </div>
    );
}

export default Card;
