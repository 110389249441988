/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../component/Loader';
import QuestionListingComponent from '../component/QuestionComponent';
import UserBtn from '../component/UserBtn';
import { getBusinessFromToken } from '../helper/services/apis/business-services';
import { getPlaneQuestions } from '../helper/services/apis/question-services';
import { checkIsTokenValidForPayment } from '../helper/services/apis/user-services';
import UserLayout from '../layouts/UserLayout';

function QuestionsSurvey() {
    const params = useParams();
    const navigate = useNavigate();
    const [token, setToken] = useState('');
    const [isLoader, setIsLoader] = useState(true);
    const [dataRows, setDataRows] = useState([]);

    const termsOnClickHandler = () => {
        navigate('/terms-and-condition');
    };

    const closeBtnOnClickHandler = () => {
        navigate(-1);
    };

    const initQuestions = async (id, categoryId) => {
        const result = await getPlaneQuestions(id, categoryId).catch((e) => {
            setDataRows([]);
        });

        const mapedResult =
            result.data && result.data.length
                ? result.data.map((question, index) => ({
                      className: '',
                      cells: [
                          { template: <strong>{index + 1}.</strong> },
                          {
                              template: <span>{question.question}</span>
                          },
                          {
                              template:
                                  question.questionType === 'Boolean' ? (
                                      <span>Si/No</span>
                                  ) : (
                                      <span>Numero in %</span>
                                  )
                          }
                      ]
                  }))
                : [];
        setDataRows([...mapedResult]);
    };

    const validateToken = async (t) => {
        if (t && t.length) {
            const result = await checkIsTokenValidForPayment(t);
            if (result.data.isValid) {
                const businessResult = await getBusinessFromToken(t);
                if (businessResult && businessResult.ok) {
                    initQuestions(
                        businessResult.data.planeId,
                        businessResult.data.businessCategoryId
                    );
                }
                setIsLoader(false);
            } else {
                navigate('/');
            }
        }
    };

    useEffect(() => {
        setToken(params.token);
    }, []);

    useEffect(() => {
        validateToken(token);
    }, [token]);

    return isLoader ? (
        <Loader />
    ) : (
        <UserLayout title="DOMANDE DEL " subtitle="QUESTIONARIO">
            <section>
                <p>
                    È possibile consultare qualsiasi termine o definizione di cui non si conosce il
                    signifcato premendo qui:
                </p>
                <UserBtn className="mt-6 mb-12 block" onClick={termsOnClickHandler}>
                    TERMINI E DEFINIZIONI
                </UserBtn>

                <QuestionListingComponent rows={dataRows} />

                <UserBtn className="my-12 block mx-auto" onClick={closeBtnOnClickHandler}>
                    CHIUDI
                </UserBtn>
            </section>
        </UserLayout>
    );
}

export default QuestionsSurvey;
