/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from '../assets/images/logo.png';
import UserFooter from '../component/UserFooter';
import ConfigContext from '../store/ConfigContext';

function Maintenance() {
    const ctx = useContext(ConfigContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!ctx.isInMaintenance) {
            navigate('/');
        }
    }, []);

    return (
        <div className="flex flex-col h-full overflow-auto">
            <header className="w-full home-header-background">
                <div className="w-full h-[475px] flex flex-col justify-center text-center text-white">
                    <span className="text-8xl text-center text-white font-sans font-semibold">
                        WINNING WOMEN
                    </span>
                    <span className="text-8xl text-center text-white font-sans font-semibold">
                        INSTITUTE
                    </span>

                    <p className="mt-3 tetx-lg">
                        Promuove la parità di genere certificandola nelle aziende
                    </p>

                    <img className="h-28 w-28 mx-auto" src={Logo} alt="logo" />
                </div>
            </header>

            <main>
                <div className="w-full h-80 flex items-center justify-center space-x-8 bg-gray-100">
                    <div>
                        <p className="font-light font-sans text-primary-900 mb-2 text-lg">
                            CHI SIAMO
                        </p>
                        <div className="text-primary-900 text-2xl">WINNING WOMEN</div>
                        <div className="text-primary-900 text-2xl">INSTITUTE</div>
                    </div>
                    <div>
                        <p className="text-gray-400">
                            Winning Women Institute® nasce per <strong>diffondere</strong> il
                            principio della
                            <br />
                            <strong>parità di genere</strong> all'interno del mondo del lavoro per{' '}
                            <strong>
                                superare e <br />
                                prevenire
                            </strong>{' '}
                            qualsiasi forma di <strong>discriminazione</strong> basata sul genere.
                        </p>
                    </div>
                    <div>
                        <a
                            href="https://winningwomeninstitute.org/"
                            target="_blank"
                            className="text-white bg-primary-900 px-8 py-4 cursor-pointer"
                            rel="noreferrer">
                            Scopri di più
                        </a>
                    </div>
                </div>

                <div className="text-center text-primary-900 p-40">
                    <h1 className="mb-6 text-4xl">
                        <b>SITO IN MANUTENZIONE</b>
                    </h1>
                    <p>
                        Stiamo lavorando per migliorare il servizio, torneremo operativi nel minor
                        tempo possibile.
                    </p>
                </div>
            </main>

            <UserFooter />
        </div>
    );
}

export default Maintenance;
