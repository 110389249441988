/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';

export const createPaymentForBusiness = async (payload) => {
    const result = await axiosInstance.post(`v1/${API_ENDPOINT.PAYMENT}`, payload);
    return result;
};

export const getPaymentIntent = async (token) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.PAYMENT}/${token}`);
    return result;
};

export const updatePaymentStatus = async (paymentId, payload) => {
    const result = await axiosInstance.put(
        `v1/${API_ENDPOINT.UPDATE_STATUS}/${paymentId}`,
        payload
    );
    return result;
};

export const deletePayment = async (token) => {
    const result = await axiosInstance.delete(`v1/${API_ENDPOINT.PAYMENT}/${token}`);
    return result;
};
