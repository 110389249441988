import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';

export const submitSurvey = async (payload) => {
    const result = await axiosInstance.post(`v1/${API_ENDPOINT.SURVEY}`, payload);
    return result;
};

export const saveSurvey = async (payload) => {
    const result = await axiosInstance.post(`v1/update-${API_ENDPOINT.SURVEY}`, payload);
    return result;
};

export const getBusinessSurvey = async (id) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.SURVEY}/${id}`);
    return result;
};

export const getSavedBusinessSurvey = async (token) => {
    const result = await axiosInstance.get(`v1/business-${API_ENDPOINT.SURVEY}/${token}`);
    return result;
};

export const sendSurveyLink = async (token) => {
    const result = await axiosInstance.post(`v1/${API_ENDPOINT.SEND_LINK}/${token}`);
    return result;
};
export const add = async () => {};
