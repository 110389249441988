/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import UserBtn from '../component/UserBtn';
import UserLayout from '../layouts/UserLayout';
import data from '../assets/data/data.json';
import PolicyDOC from '../assets/doc/Privacy Policy.docx';
import TerminiDOC from '../assets/doc/Termini di utilizzo.docx';
import NoteDOC from '../assets/doc/Note legali.docx';
import CondizioniDOC from '../assets/doc/Condizioni di acquisto.docx';

import { getCurrentYear } from '../helper/utility';
import { getBusinessesCategories } from '../helper/services/apis/business-category-services';
import { CAP_REGEX, EMAIL_REGEX, PHONE_REGEX } from '../constants';
import { createBusiness } from '../helper/services/apis/business-services';

function CreateAgency() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors }
    } = useForm();
    const acceptTerms = watch('acceptTerms', false);

    const [businessCategories, setBusinessCategory] = useState([]);

    const initBusinessCategory = async () => {
        const categories = await getBusinessesCategories().catch((e) => {
            // eslint-disable-next-line no-console
            console.error(e);
            setBusinessCategory([]);
        });
        const items = [...categories.data];
        const selectedIndex = items.findIndex((item) => item.code === 'Tutti');
        items.splice(selectedIndex, 1);
        setBusinessCategory([...items]);
    };

    const onSubmitHandler = async (newData) => {
        const result = await createBusiness(newData).catch((e) => console.log(e));
        if (result && result.ok) {
            navigate(`/privacy-policy/${result.data.token}`);
        }
    };

    const onCategoryChangeHandler = (event) => {
        const { code } = event.target.options[event.target.selectedIndex].dataset;
        setValue('categoryCode', code);
        setValue('businessCategoryId', event.target.value, { shouldValidate: true });
    };

    useEffect(() => {
        initBusinessCategory();
    }, []);

    return (
        <UserLayout title="INSERISCI LE INFORMZIONI DELLA TUA" subtitle="AZIENDA">
            <section className="text-center">
                <p className="mb-12">
                    <strong className="text-xl">{data.createAgency.title}</strong>
                </p>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="flex flex-wrap">
                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.name ? 'error-input' : ''
                            }`}>
                            <label htmlFor="ragione-sociale"> RAGIONE SOCIALE* </label>
                            <input
                                id="ragione-sociale"
                                type="text"
                                placeholder="Ragione sociale"
                                {...register('name', { required: true, maxLength: 100 })}
                            />
                            {errors?.name?.type === 'required' && (
                                <span>Ragione sociale è obbligatorio</span>
                            )}
                            {errors?.name?.type === 'maxLength' && (
                                <span>Questo campo non può superare i 100 caratteri</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.vatNumber ? 'error-input' : ''
                            }`}>
                            <label htmlFor="codice-fiscale"> CODICE FISCALE/P.IVA* </label>
                            <input
                                id="codice-fiscale"
                                type="text"
                                placeholder="Codice fiscale/P.IVA"
                                {...register('vatNumber', { required: true, maxLength: 100 })}
                            />
                            {errors?.vatNumber?.type === 'required' && (
                                <span>Codice Fiscale/PIVA è obbligatorio</span>
                            )}
                            {errors?.vatNumber?.type === 'maxLength' && (
                                <span>Questo campo non può superare i 100 caratteri</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.registeredOffice ? 'error-input' : ''
                            }`}>
                            <label htmlFor=""> SEDE LEGALE* </label>
                            <input
                                id="sede-legale"
                                type="text"
                                placeholder="Sede legale"
                                {...register('registeredOffice', {
                                    required: true,
                                    maxLength: 100
                                })}
                            />
                            {errors?.registeredOffice?.type === 'required' && (
                                <span>Sede legale è obbligatorio</span>
                            )}
                            {errors?.registeredOffice?.type === 'maxLength' && (
                                <span>Questo campo non può superare i 100 caratteri</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.common ? 'error-input' : ''
                            }`}>
                            <label htmlFor="comune"> COMUNE* </label>
                            <input
                                id="comune"
                                type="text"
                                placeholder="Comune"
                                {...register('common', { required: true, maxLength: 100 })}
                            />
                            {errors?.common?.type === 'required' && (
                                <span>Comune è obbligatorio</span>
                            )}
                            {errors?.common?.type === 'maxLength' && (
                                <span>Questo campo non può superare i 100 caratteri</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.postalCode ? 'error-input' : ''
                            }`}>
                            <label htmlFor="cap"> CAP* </label>
                            <input
                                id="cap"
                                type="text"
                                placeholder="Cap"
                                {...register('postalCode', { required: true, pattern: CAP_REGEX })}
                            />
                            {errors?.postalCode?.type === 'required' && (
                                <span>Cap è obbligatorio</span>
                            )}
                            {errors?.postalCode?.type === 'pattern' && (
                                <span>CAP non è valido</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.address ? 'error-input' : ''
                            }`}>
                            <label htmlFor="indirizzo"> INDIRIZZO* </label>
                            <input
                                id="indirizzo"
                                type="text"
                                placeholder="Indirizzo"
                                {...register('address', { required: true, maxLength: 255 })}
                            />
                            {errors?.address?.type === 'required' && (
                                <span>Indirizzo è obbligatorio</span>
                            )}
                            {errors?.address?.type === 'maxLength' && (
                                <span>Questo campo non può superare i 255 caratteri</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.email ? 'error-input' : ''
                            }`}>
                            <label htmlFor="email"> E-MAIL* </label>
                            <input
                                id="email"
                                type="text"
                                placeholder="Email"
                                {...register('email', { required: true, pattern: EMAIL_REGEX })}
                            />
                            {errors?.email?.type === 'required' && (
                                <span>Eamil è obbligatorio</span>
                            )}
                            {errors?.email?.type === 'pattern' && (
                                <span>L&apos; email non è valida</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.pec ? 'error-input' : ''
                            }`}>
                            <label htmlFor="pec"> PEC* </label>
                            <input
                                id="pec"
                                type="text"
                                placeholder="Pec"
                                {...register('pec', { required: true, maxLength: 100 })}
                            />
                            {errors?.pec?.type === 'required' && <span>Pec è obbligatorio</span>}
                            {errors?.pec?.type === 'maxLength' && (
                                <span>Questo campo non può superare i 100 caratteri</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.telephoneNumber ? 'error-input' : ''
                            }`}>
                            <label htmlFor="telefono"> TELEFONO* </label>
                            <input
                                id="telefono"
                                type="text"
                                placeholder="Telefono"
                                {...register('telephoneNumber', {
                                    required: true,
                                    pattern: PHONE_REGEX
                                })}
                            />
                            {errors?.telephoneNumber?.type === 'required' && (
                                <span>Telefono è obbligatorio</span>
                            )}
                            {errors?.telephoneNumber?.type === 'pattern' && (
                                <span>Il numero di telefono non è valido</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.numberOfEmployees ? 'error-input' : ''
                            }`}>
                            <label htmlFor="occupati-alle-dipendenze">
                                OCCUPATI ALLE DIPENDENZE AL 31/12/{getCurrentYear() - 1}*
                                {/* <span
                                    className="bg-black text-white rounded-full w-5 h-5 inline-block text-center ml-1 cursor-pointer"
                                    title="Persona occupata in un’unità giuridica, come lavoratore/lavoratrice indipendente o dipendente (a tempo pieno, a tempo parziale o con contratto di formazione lavoro), anche se temporaneamente assente (per servizio, ferie, malattia, sospensione dal lavoro, Cassa integrazione guadagni, ecc.). Comprende il/i titolare/i della/e impresa/e partecipante/i direttamente alla gestione, i cooperatori (soci di cooperative che, come corrispettivo della loro prestazione, percepiscono un compenso proporzionato all’opera resa e una quota degli utili dell’impresa), i coadiuvanti familiari (parenti o affini del titolare che prestano lavoro manuale senza una prefissata retribuzione contrattuale), dirigenti, quadri, impiegati, operai e apprendisti.">
                                    ?
                                </span> */}
                                <span className="bg-black text-white rounded-full w-5 h-5 inline-block text-center ml-1 cursor-pointer group relative">
                                    ?
                                    <div className="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex">
                                        <span className="relative z-10 p-2 text-xs text-white whitespace-no-wrap bg-black shadow-lg w-56">
                                            Persona occupata in un’unità giuridica, come
                                            lavoratore/lavoratrice indipendente o dipendente (a
                                            tempo pieno, a tempo parziale o con contratto di
                                            formazione lavoro), anche se temporaneamente assente
                                            (per servizio, ferie, malattia, sospensione dal lavoro,
                                            Cassa integrazione guadagni, ecc.). Comprende il/i
                                            titolare/i della/e impresa/e partecipante/i direttamente
                                            alla gestione, i cooperatori (soci di cooperative che,
                                            come corrispettivo della loro prestazione, percepiscono
                                            un compenso proporzionato all’opera resa e una quota
                                            degli utili dell’impresa), i coadiuvanti familiari
                                            (parenti o affini del titolare che prestano lavoro
                                            manuale senza una prefissata retribuzione contrattuale),
                                            dirigenti, quadri, impiegati, operai e apprendisti.
                                        </span>
                                        <div className="w-3 h-3 -mt-2 rotate-45 bg-black" />
                                    </div>
                                </span>
                            </label>
                            <input
                                id="occupati-alle-dipendenze"
                                type="number"
                                placeholder="Occupati alle dipendenze"
                                {...register('numberOfEmployees', {
                                    required: true
                                })}
                            />
                            {errors?.numberOfEmployees?.type === 'required' && (
                                <span>Occupati alle dipendenze è obbligatorio</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.businessCategoryId ? 'error-input' : ''
                            }`}>
                            <label htmlFor="attività-economica-esercitata">
                                ATTIVITÀ ECONOMICA ESERCITATA*
                            </label>
                            <select
                                id="attività-economica-esercitata"
                                {...register('businessCategoryId', { required: true })}
                                onChange={onCategoryChangeHandler}>
                                <option value="">Selezionare</option>
                                {businessCategories.map((category) => (
                                    <option
                                        value={category.id}
                                        key={category.id}
                                        data-code={category.code}>
                                        {category.title}
                                    </option>
                                ))}
                            </select>
                            {errors?.businessCategoryId?.type === 'required' && (
                                <span className="">
                                    Attività economica esercitata è obbligatorio
                                </span>
                            )}
                        </div>

                        <div className="w-1/2 px-6 my-5 form-controller-vertical-label text-left">
                            <label htmlFor="codice-ateco"> CODICE ATECO* </label>
                            <input
                                id="codice-ateco"
                                type="Codice ateco"
                                placeholder="Codice ateco"
                                {...register('categoryCode')}
                                disabled
                            />
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.previousYearTurnover ? 'error-input' : ''
                            }`}>
                            <label htmlFor="fatturato-anno-precedente">
                                FATTURATO ANNO PRECEDENTE*
                            </label>
                            <input
                                id="fatturato-anno-precedente"
                                type="number"
                                step="0.01"
                                placeholder="Fatturato anno precedente"
                                {...register('previousYearTurnover', {
                                    required: true
                                })}
                            />
                            {errors?.previousYearTurnover?.type === 'required' && (
                                <span>Fatturato anno precedente è obbligatorio</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.sdiCode ? 'error-input' : ''
                            }`}>
                            <label htmlFor="fatturato-anno-precedente">
                                CODICE SDI (per fatturazione elettronica) *
                            </label>
                            <input
                                id="fatturato-anno-precedente"
                                type="text"
                                placeholder="codice SDI"
                                {...register('sdiCode', { required: true, maxLength: 100 })}
                            />
                            {errors?.sdiCode?.type === 'required' && (
                                <span>Codice SDI è obbligatorio</span>
                            )}
                            {errors?.sdiCode?.type === 'maxLength' && (
                                <span>Questo campo non può superare i 100 caratteri</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.contactPhone ? 'error-input' : ''
                            }`}>
                            <label htmlFor="telefono-referente"> TELEFONO REFERENTE* </label>
                            <input
                                id="telefono-referente"
                                type="text"
                                placeholder="Telefono referente"
                                {...register('contactPhone', {
                                    required: true,
                                    pattern: PHONE_REGEX
                                })}
                            />
                            {errors?.contactPhone?.type === 'required' && (
                                <span>Telefono referente è obbligatorio</span>
                            )}
                            {errors?.contactPhone?.type === 'pattern' && (
                                <span>Il numero di telefono non è valido</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.contactEmail ? 'error-input' : ''
                            }`}>
                            <label htmlFor="mail-referente"> MAIL REFERENTE* </label>
                            <input
                                id="mail-referente"
                                type="text"
                                placeholder="Mail referente"
                                {...register('contactEmail', {
                                    required: true,
                                    pattern: EMAIL_REGEX
                                })}
                            />
                            {errors?.contactEmail?.type === 'required' && (
                                <span>Mail referente è obbligatorio</span>
                            )}
                            {errors?.contactEmail?.type === 'maxLength' && (
                                <span>L&apos; email non è valida</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.contactName ? 'error-input' : ''
                            }`}>
                            <label htmlFor="nome-referente"> NOME REFERENTE* </label>
                            <input
                                id="nome-referente"
                                type="text"
                                placeholder="Nome referente"
                                {...register('contactName', { required: true, maxLength: 100 })}
                            />
                            {errors?.contactName?.type === 'required' && (
                                <span>Nome referente è obbligatorio</span>
                            )}
                            {errors?.contactName?.type === 'maxLength' && (
                                <span>Questo campo non può superare i 100 caratteri</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.group ? 'error-input' : ''
                            }`}>
                            <label htmlFor="gruppo"> GRUPPO </label>
                            <input
                                id="gruppo"
                                type="text"
                                placeholder="Gruppo"
                                {...register('group', { maxLength: 100 })}
                            />
                            {errors?.group?.type === 'maxLength' && (
                                <span>Questo campo non può superare i 100 caratteri</span>
                            )}
                        </div>

                        <div
                            className={`w-full px-6 my-5 form-controller-vertical-label text-left"> ${
                                errors?.note ? 'error-input' : ''
                            }`}>
                            <label htmlFor="note"> Note </label>
                            <textarea
                                id="note"
                                {...register('note', { maxLength: 500 })}
                                rows="6"
                            />
                            {errors?.note?.type === 'maxLength' && (
                                <span>Questo campo non può superare i 500 caratteri</span>
                            )}
                        </div>
                    </div>
                    <div className="mb-12">
                        <input
                            type="checkbox"
                            className="mr-2"
                            {...register('acceptTerms', { required: true })}
                        />{' '}
                        Dichiaro di aver letto ed accetto la{' '}
                        <a href={PolicyDOC} target="_blank" rel="noreferrer">
                            <strong className="text-primary-900 underline cursor-pointer">
                                Privacy Policy
                            </strong>
                        </a>
                        , i{' '}
                        <a href={TerminiDOC} target="_blank" rel="noreferrer">
                            <strong className="text-primary-900 underline cursor-pointer">
                                Termini di utilizzo
                            </strong>
                        </a>
                        , le{' '}
                        <a href={NoteDOC} target="_blank" rel="noreferrer">
                            <strong className="text-primary-900 underline cursor-pointer">
                                Note legali{' '}
                            </strong>
                        </a>
                        e le{' '}
                        <a href={CondizioniDOC} target="_blank" rel="noreferrer">
                            <strong className="text-primary-900 underline cursor-pointer">
                                Condizioni di acquisto
                            </strong>
                        </a>
                        .
                    </div>
                    <UserBtn className="mt-6 mb-12" type="submit" disable={!acceptTerms}>
                        AVANTI
                    </UserBtn>
                </form>

                <p className="mb-6">Per richiedere informazioni:</p>

                <p>Telefono : 02 36591069</p>

                <p>
                    Mail :{' '}
                    <a href="mailto:segreteria@winningwomeninstitute.org" className="text-sky-500">
                        segreteria@winningwomeninstitute.org
                    </a>
                </p>
            </section>
        </UserLayout>
    );
}

export default CreateAgency;
