/* eslint-disable react/prop-types */
import React from 'react';
import UserFooter from '../component/UserFooter';
import UserHeader from '../component/UserHeader';

function UserLayout({ title, subtitle, children }) {
    return (
        <div className="flex flex-col h-full">
            <UserHeader title={title} subtitle={subtitle} />
            <main className="p-16 w-full max-w-7xl mx-auto">{children}</main>
            <UserFooter />
        </div>
    );
}

export default UserLayout;
