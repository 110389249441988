/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import UserBtn from '../component/UserBtn';

import Logo from '../assets/images/logo.png';
import UserFooter from '../component/UserFooter';

function Home() {
    const navigate = useNavigate();

    const beginsBtnOnClickHandler = () => {
        navigate('/agency');
    };

    return (
        <div className="flex flex-col h-full overflow-auto">
            <header className="w-full home-header-background">
                <div className="w-full h-[475px] flex flex-col justify-center text-center text-white">
                    <span className="text-8xl text-center text-white font-sans font-semibold">
                        WINNING WOMEN
                    </span>
                    <span className="text-8xl text-center text-white font-sans font-semibold">
                        INSTITUTE
                    </span>

                    <p className="mt-3 tetx-lg">
                        Promuove la parità di genere certificandola nelle aziende
                    </p>

                    <img className="h-28 w-28 mx-auto" src={Logo} alt="logo" />
                </div>
            </header>

            <main>
                <div className="w-full h-80 flex items-center justify-center space-x-8 bg-gray-100">
                    <div>
                        <p className="font-light font-sans text-primary-900 mb-2 text-lg">
                            CHI SIAMO
                        </p>
                        <div className="text-primary-900 text-2xl">WINNING WOMEN</div>
                        <div className="text-primary-900 text-2xl">INSTITUTE</div>
                    </div>
                    <div>
                        <p className="text-gray-400">
                            Winning Women Institute® nasce per <strong>diffondere</strong> il
                            principio della
                            <br />
                            <strong>parità di genere</strong> all'interno del mondo del lavoro per{' '}
                            <strong>
                                superare e <br />
                                prevenire
                            </strong>{' '}
                            qualsiasi forma di <strong>discriminazione</strong> basata sul genere.
                        </p>
                    </div>
                    <div>
                        <a
                            href="https://winningwomeninstitute.org/"
                            target="_blank"
                            className="text-white bg-primary-900 px-8 py-4 cursor-pointer"
                            rel="noreferrer">
                            Scopri di più
                        </a>
                    </div>
                </div>

                <div className="text-center text-primary-900 p-40">
                    <h1 className="mb-6 text-4xl">COMPILA IL QUESTIONARIO ON LINE</h1>
                    <h1 className="mb-6 text-4xl max-w-7xl mx-auto">
                        Per sapere se la tua azienda può ottenere la Certificazione sulla Parità di
                        Genere secondo la Prassi di Riferimento Nazionale UNI/PDR 125:2022
                    </h1>
                    <p>
                        Occorrono pochi minuti per completare il questionario ed avere un feedback
                    </p>
                </div>

                <div className="w-full h-80 flex items-center justify-center space-x-8 bg-gray-100 p-8 text-lg">
                    <div className="max-w-7xl mx-auto text-primary-900">
                        <p className="font-semibold mb-6">
                            Ti ricordiamo che la Certificazione Nazionale può dare alla tua azienda
                            i seguenti vantaggi:{' '}
                        </p>
                        <ul className="list-disc">
                            <li>
                                Riduzione della cosiddetta ‘garanzia provvisoria’ (fino al 30%)
                                nell’ambito dei bandi o avvisi
                            </li>
                            <li>
                                Criteri premiali nei bandi di gara, negli avvisi o negli inviti
                                (maggior punteggio)
                            </li>
                            <li>Sgravi contributivi</li>
                        </ul>
                    </div>
                </div>

                <div className="text-primary-900 text-center p-12">
                    <p>PROCEDI E COMPLETA </p>
                    <p className="my-12">
                        <strong className="text-2xl">IL QUESTIONARIO</strong>
                    </p>
                    <UserBtn className="mb-12" onClick={beginsBtnOnClickHandler}>
                        INIZIA
                    </UserBtn>
                </div>
            </main>

            <UserFooter />
        </div>
    );
}

export default Home;
