import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserBtn from '../component/UserBtn';
import UserLayout from '../layouts/UserLayout';

function Complete() {
    const navigate = useNavigate();

    const goToHomeBtnOnClickHandler = () => {
        navigate('/');
    };

    return (
        <UserLayout title="" subtitle="WINNING WOMAN INSTITUTE">
            <section className="text-center text-2xl">
                <div className="flex flex-col space-y-12">
                    <p>
                        <strong className="text-4xl">
                            Complimenti! Hai completato il questionario di valutazione! Ti invieremo
                            per mail il risultato ottenuto entro 48h.
                        </strong>
                    </p>
                </div>

                <UserBtn className="my-12 block mx-auto" onClick={goToHomeBtnOnClickHandler}>
                    TORNA ALLA HOME
                </UserBtn>
            </section>
        </UserLayout>
    );
}

export default Complete;
