/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from '@stripe/react-stripe-js';

import UserBtn from '../component/UserBtn';
import UserLayout from '../layouts/UserLayout';
import data from '../assets/data/data.json';
import { checkIsTokenValidForPayment } from '../helper/services/apis/user-services';
import Loader from '../component/Loader';
import {
    createPaymentForBusiness,
    deletePayment,
    getPaymentIntent
} from '../helper/services/apis/payment-services';
import { getUserPlaneAmount } from '../helper/services/apis/plane-services';

const useOptions = () => {
    const fontSize = '1rem';
    const options = useMemo(
        () => ({
            style: {
                base: {
                    iconColor: '#41242B',
                    '::placeholder': {
                        color: '#CFD7E0'
                    }
                },
                invalid: {
                    color: '#dc3545'
                }
            },
            showIcon: true,
            placeholder: 'xxxx xxxx xxxx xxxx'
        }),
        [fontSize]
    );

    return options;
};

function Payment() {
    const params = useParams();
    const navigate = useNavigate();

    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const [amount, setAmount] = useState('0');
    const [paymentType, setPaymentType] = useState('card');
    const [isLoader, setIsLoader] = useState(true);

    const [token, setToken] = useState('');

    const [isProcessing, setProcessingTo] = useState(false);
    const paymentToast = useRef(null);

    const validateToken = async (t) => {
        if (t && t.length) {
            const result = await checkIsTokenValidForPayment(t);
            if (result.data.isValid && !result.data.isPayment) {
                const planeAmount = await getUserPlaneAmount(t);
                if (planeAmount && planeAmount.ok) {
                    setAmount(planeAmount.data);
                }
                setIsLoader(false);
            } else {
                navigate('/');
            }
        }
    };

    const createPayment = async (paymentMethod) => {
        const playload = {
            paymentType: paymentMethod,
            token
        };
        try {
            const result = await createPaymentForBusiness(playload);
            if (result && result.ok) {
                return result;
            }
            toast.error('Error');
            return false;
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCardPaymentSubmit = async (event) => {
        event.preventDefault();

        setProcessingTo(true);

        /* 
            Validate card
        */
        const cardElement = elements.getElement(CardNumberElement);
        const validateCard = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
        });

        if (validateCard.error) {
            toast.error(validateCard.error.message);
            setProcessingTo(false);
            return false;
        }

        /* 
           Create payment Intent 
       */
        paymentToast.current = toast.loading('Please wait processing...');

        await createPayment('Card');

        // return false;
        const paymentIntent = await getPaymentIntent(token);

        const paymentMethodReq = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: paymentIntent.data.billingDetails.name,
                email: paymentIntent.data.billingDetails.email,
                address: {
                    line1: paymentIntent.data.billingDetails.address.line1,
                    postal_code: paymentIntent.data.billingDetails.address.postalCode
                }
            }
        });

        if (paymentMethodReq.error) {
            toast.update(paymentToast.current, {
                render: 'Error, please try again.',
                type: 'error',
                autoClose: 2000,
                className: 'rotateY animated',
                isLoading: false
            });

            setProcessingTo(false);
            // TODO : Delete Payment
            deletePayment(token);
            return false;
        }

        const confirmedCardPayment = await stripe.confirmCardPayment(
            paymentIntent.data.clientSecret,
            {
                payment_method: paymentMethodReq.paymentMethod.id
            }
        );

        if (confirmedCardPayment.error) {
            toast.update(paymentToast.current, {
                render: 'Error, please try again.',
                type: 'error',
                autoClose: 2000,
                className: 'rotateY animated',
                isLoading: false
            });

            setProcessingTo(false);
            // TODO : Delete Payment
            deletePayment(token);
            return false;
        }
        toast.update(paymentToast.current, {
            render: 'Payment Successfull.',
            type: 'success',
            autoClose: 2000,
            className: 'rotateY animated',
            isLoading: false
        });
        navigate(`/success-payment/${token}`);
    };

    const handleCardDetailsChange = () => {};

    const processOnlinePaymentOnClickHandler = async () => {
        await createPayment('Banking');
        toast.success('Success');
        navigate('/online-payment');
    };

    const paymentTypeOnChangeHandler = (event) => {
        setPaymentType(event.target.value);
    };

    const getPaymentTypeTemplate = () => {
        let template = null;

        if (paymentType === 'card') {
            template = (
                <div className="my-12">
                    <strong>2. Inserisci i dati della tua carta</strong>

                    <form className="mt-6 w-96 mx-auto" onSubmit={handleCardPaymentSubmit}>
                        <div className="form-controller-vertical-label mb-6 text-left">
                            <label> Nome sulla carta </label>
                            <input type="text" placeholder="Nome e cognome" />
                        </div>
                        <div className="form-controller-vertical-label mb-6 text-left">
                            <label> Numero carta </label>
                            {/* <input type="text" placeholder="xxxx xxxx xxxx xxxx" /> */}
                            <CardNumberElement
                                options={options}
                                onChange={handleCardDetailsChange}
                            />
                        </div>
                        <div className="flex">
                            <div className="form-controller-vertical-label mb-6 mr-6 text-left w-5/12">
                                <label> Data di scadenza </label>
                                <CardExpiryElement onChange={handleCardDetailsChange} />
                            </div>
                            <div className="form-controller-vertical-label mb-6 text-left w-5/12">
                                <label> CVV </label>
                                <CardCvcElement onChange={handleCardDetailsChange} />
                            </div>
                        </div>

                        <UserBtn
                            type="submit"
                            className="mt-6 mb-12"
                            disable={isProcessing || !stripe}>
                            PROCEDI
                        </UserBtn>
                    </form>
                </div>
            );
        } else {
            template = (
                <div className="my-12">
                    <strong>2. Invia il bonifico a questo IBAN</strong>
                    <div className="mt-6 text-xl">
                        <strong>BAN: IT72U0306901788100000000378</strong>
                        <p>Causale : Questionario certificazione uni/pdr</p>
                    </div>
                    <UserBtn
                        className="mt-6 mb-12"
                        onClick={processOnlinePaymentOnClickHandler}
                        disable={isProcessing}>
                        PROCEDI
                    </UserBtn>
                </div>
            );
        }
        return template;
    };

    useEffect(() => {
        setToken(params.token);
    }, []);

    useEffect(() => {
        validateToken(token);
    }, [token]);

    return isLoader ? (
        <Loader />
    ) : (
        <UserLayout title="PROCEDI AL" subtitle="PAGAMENTO">
            <section className="text-center mx-44">
                <p className="mb-12">
                    <strong className="text-xl">{data.payment.title}</strong>
                </p>

                <div className="mb-12">
                    <strong className="text-6xl">{amount} €</strong>
                </div>
                <hr />

                <div className="my-12">
                    <strong>1. Scegli la tipologia di pagamento</strong>
                    <div className="my-6 flex justify-center">
                        <div className="form-controller-horizontal-label mr-6">
                            <input
                                type="radio"
                                value="card"
                                name="paymentType"
                                onChange={paymentTypeOnChangeHandler}
                                checked={paymentType === 'card'}
                                id="card"
                            />
                            <label htmlFor="card">Carta di credito</label>
                        </div>
                        <div className="form-controller-horizontal-label mr-6">
                            <input
                                type="radio"
                                value="online"
                                name="paymentType"
                                onChange={paymentTypeOnChangeHandler}
                                checked={paymentType === 'online'}
                            />
                            <label>Bonifico</label>
                        </div>
                    </div>
                </div>
                <hr />

                {getPaymentTypeTemplate()}
            </section>
        </UserLayout>
    );
}

export default Payment;
