import { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Payment from './pages/Payment';
import SuccessCardPayment from './pages/SuccessCardPayment';
import OnlinePayment from './pages/OnlinePayment';
import QuestionsSurvey from './pages/QuestionsSurvey';
import TermsAndConditions from './pages/TermsAndConditions';
import FillSurvey from './pages/FillSurvey';
import CreateAgency from './pages/CreateAgency';
import Complete from './pages/Complete';
import Admin from './pages/admin/Admin';
import Login from './pages/admin/Login';
import Dashboard from './pages/admin/dashboard/Dashboard';
import ClientsListing from './pages/admin/dashboard/ClientsListing';
import Client from './pages/admin/dashboard/Client';
import Plans from './pages/admin/dashboard/Plans';
import Questions from './pages/admin/dashboard/Questions';
import Evaluation from './pages/admin/dashboard/Evaluation';
import EvaluationListing from './pages/admin/dashboard/EvaluationListing';
import Storage from './helper/services/common/storage-helper-services';
import { getDevice, getEnvironmentKeyValue } from './helper/utility';
import Setting from './pages/admin/dashboard/Setting';
import Logo from './assets/images/logo.png';
import Maintenance from './pages/Maintenance';
import ConfigContext, { ConfigContextProvider } from './store/ConfigContext';
import SaveSurvey from './pages/saveSurvey';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(getEnvironmentKeyValue('STRIPE_PUBLIC_KEY'));

/**
 * This component is responsible for authentication
 */
function RequireAuth() {
    const token = Storage.get('auth-token');
    if (!token) {
        window.location.href = '/';
        return null;
    }
    return (
        <Dashboard>
            <Outlet />
        </Dashboard>
    );
}

function CheckForMaintenance() {
    const ctx = useContext(ConfigContext);

    const isMaintenance = ctx.isInMaintenance;
    if (isMaintenance) {
        window.location.href = '/maintenance';
        return null;
    }
    return <Outlet />;
}

console.log('Environment : ', process.env.NODE_ENV);

function App() {
    const [deviceType, setDeviceType] = useState(getDevice(window.innerWidth));

    const handleWindowResize = () => {
        setDeviceType(getDevice(window.innerWidth));
    };

    const getDeviceView = () => {
        return deviceType === 'mobile' ? (
            <div className="flex flex-col h-full items-center text-center text-4xl py-16 px-8 space-y-24">
                <h4 className="text-5xl font-bold">WINNING WOMAN INSTITUTE</h4>
                <div className="px-6 py-1.5 h-32">
                    <img className="h-full" src={Logo} alt="logo" />
                </div>
                <p>Per favore accedi da desktop per una visualizzazione corretta.</p>
            </div>
        ) : (
            <ConfigContextProvider>
                <BrowserRouter>
                    <Routes>
                        <Route exact path="/maintenance" element={<Maintenance />} />
                        <Route exact path="/" element={<CheckForMaintenance />}>
                            <Route exact path="/" element={<Home />} />
                            <Route exact path="/agency" element={<CreateAgency />} />
                            <Route
                                exact
                                path="/privacy-policy/:token"
                                element={<PrivacyPolicy />}
                            />
                            <Route
                                exact
                                path="/make-payment/:token"
                                element={
                                    <Elements stripe={stripePromise}>
                                        <Payment />
                                    </Elements>
                                }
                            />
                            <Route
                                exact
                                path="/success-payment/:token"
                                element={<SuccessCardPayment />}
                            />
                            <Route exact path="/online-payment" element={<OnlinePayment />} />
                            <Route
                                exact
                                path="/terms-and-condition"
                                element={<TermsAndConditions />}
                            />
                            <Route
                                exact
                                path="/questions-survey/:token"
                                element={<QuestionsSurvey />}
                            />
                            <Route exact path="/fill-survey/:token" element={<FillSurvey />} />
                            <Route exact path="/save-survey" element={<SaveSurvey />} />
                            <Route exact path="/complete" element={<Complete />} />
                        </Route>

                        <Route exact path="/admin" element={<Admin />}>
                            <Route exact path="login" element={<Login />} />
                            <Route exact path="dashboard" element={<RequireAuth />}>
                                <Route
                                    exact
                                    path=""
                                    element={<Navigate replace to="/admin/dashboard/clients" />}
                                />
                                <Route exact path="clients" element={<ClientsListing />} />
                                <Route exact path="evaluations" element={<EvaluationListing />} />
                                <Route exact path="questions" element={<Questions />} />
                                <Route exact path="plans" element={<Plans />} />
                                <Route exact path="client/:id" element={<Client />} />
                                <Route exact path="profile" element={<Setting />} />
                                <Route exact path="evaluation/:id" element={<Evaluation />} />
                                <Route
                                    exact
                                    path="*"
                                    element={<Navigate replace to="/admin/login" />}
                                />
                            </Route>
                            <Route
                                exact
                                path="*"
                                element={<Navigate replace to="/admin/login" />}
                            />
                        </Route>
                        <Route exact path="*" element={<Navigate replace to="/" />} />
                    </Routes>
                </BrowserRouter>
            </ConfigContextProvider>
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <div className="app-container user-bg-color relative">
            {getDeviceView()}
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastClassName="black-background"
                theme="dark"
            />
        </div>
    );
}

export default App;
