import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import UserBtn from '../component/UserBtn';
import UserLayout from '../layouts/UserLayout';

import messageSend from '../assets/images/send-message.png';

import data from '../assets/data/data.json';
import ModalComponent from '../component/modal/ModalComponent';
import Loader from '../component/Loader';
import { checkIsTokenValidForPayment } from '../helper/services/apis/user-services';
import { sendSurveyLink } from '../helper/services/apis/surveys-services';

function SuccessCardPayment() {
    const params = useParams();
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [token, setToken] = useState('');
    const processToast = useRef(null);

    const nextTestBtnOnClickHandler = async () => {
        processToast.current = toast.loading('Please wait processing...');
        await sendSurveyLink(token);
        toast.update(processToast.current, {
            render: 'Done.',
            type: 'success',
            autoClose: 2000,
            className: 'rotateY animated',
            isLoading: false
        });
        navigate('/');
    };

    const onModalCloseBtnClickHandler = () => {
        setIsOpen(false);
    };

    const continueBtnOnClickHandler = () => {
        navigate(`/fill-survey/${token}`);
    };

    const validateToken = async (t) => {
        if (t && t.length) {
            const result = await checkIsTokenValidForPayment(t);
            if (result.data.isValid && result.data.isPayment) {
                setIsLoader(false);
            } else {
                navigate('/');
            }
        }
    };

    useEffect(() => {
        setToken(params.token);
    }, []);

    useEffect(() => {
        validateToken(token);
    }, [token]);

    return isLoader ? (
        <Loader />
    ) : (
        <UserLayout title="PAGAMENTO AVVENUTO CON" subtitle="SUCCESSO!">
            <ModalComponent isOpen={isOpen} onClose={onModalCloseBtnClickHandler}>
                {/* Modal header  */}
                <div className="py-9 px-6 rounded-t border-b text-center">
                    <h1 className="text-4xl font-semibold text-primary-900">ATTENZIONE!</h1>
                </div>
                {/* Modal body  */}
                <div className="p-9">
                    <p className="mb-12 text-2xl text-center">
                        Una volta iniziato il test non è più possibile tornare indietro o rifarlo.
                        Sarà comunque possibile terminarlo in un secondo momento. Prima di iniziare
                        assicurati di avere tutte le informazioni necessarie. Per controllare le
                        domande del questionario{' '}
                        <NavLink className="text-sky-500" to={`/questions-survey/${token}`}>
                            premi qui.
                        </NavLink>
                    </p>

                    <p className="mb-12 text-2xl text-center">
                        <strong>Sei sicuro di voler continuare?</strong>
                    </p>

                    <div className="flex justify-center w-full">
                        <div>
                            <UserBtn
                                className="mt-6 mb-6 block mx-auto w-full"
                                onClick={continueBtnOnClickHandler}>
                                CONTINUA
                            </UserBtn>
                            <UserBtn
                                className="mt-6 mb-2 block mx-auto"
                                isOutline
                                onClick={() => setIsOpen(false)}>
                                INDIETRO
                            </UserBtn>
                        </div>
                    </div>
                </div>
            </ModalComponent>

            <section className="text-center text-2xl">
                <div className="mb-12">
                    <img src={messageSend} alt="successo" className="w-64 mx-auto" />
                </div>
                <p>
                    <strong className="text-4xl">{data.SuccessCardPayment.title}</strong>
                </p>

                <p className="my-6 text-2xl">
                    Abbiamo ricevuto il tuo pagamento. Una copia della fattura ti verrà inviata alla
                    tua email.
                </p>
                <p>
                    <strong>Attenzione!</strong>
                </p>
                <p className="mb-12">
                    Una volta iniziato il test non è più possibile tornare indietro o rifarlo. Prima
                    di iniziare assicurati di avere tutte le informazioni necessarie. Per
                    controllare le domande del questionario{' '}
                    <NavLink className="text-sky-500" to={`/questions-survey/${token}`}>
                        premi qui.
                    </NavLink>
                </p>

                <div className="flex justify-center w-full">
                    <div>
                        <UserBtn
                            className="mt-6 mb-12 block mx-auto w-full"
                            onClick={() => setIsOpen(true)}>
                            FAI IL TEST SUBITO
                        </UserBtn>
                        <UserBtn
                            className="mt-6 mb-2 block mx-auto"
                            isOutline
                            onClick={nextTestBtnOnClickHandler}>
                            FAI IL TEST SUCCESSIVAMENTE
                        </UserBtn>
                    </div>
                </div>

                <div className="w-56 mb-12 mx-auto text-xs">
                    Ti sarà inviata una mail contenente il link per accedere al questionario.
                </div>
            </section>
        </UserLayout>
    );
}

export default SuccessCardPayment;
