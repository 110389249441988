import React from 'react';

function Loader() {
    return (
        <div className="wwwi-spinner">
            <div className="texttransform">
                <b>L</b>
                <b>O</b>
                <b>A</b>
                <b>D</b>
                <b>I</b>
                <b>N</b>
                <b>G</b>
            </div>
        </div>
    );
}

export default Loader;
