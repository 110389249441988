import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Logo from '../../assets/images/logo.png';

import Card from '../../component/Card';
import UserBtn from '../../component/UserBtn';
import { adminLogin } from '../../helper/services/apis/admin-services';
import { sendNewPassword } from '../../helper/services/apis/user-services';
import Storage from '../../helper/services/common/storage-helper-services';

function Login() {
    const navigate = useNavigate();
    const [isEmailSent, setIsEmailSent] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const sendEmail = async () => {
        setIsEmailSent(true);
        await sendNewPassword();
    };

    const onSubmitHandler = async (loginData) => {
        const result = await adminLogin(loginData).catch((e) => toast.error(e.message));
        if (result && result.ok) {
            Storage.set('auth-token', result.data.token);
            Storage.set('profile', JSON.stringify(result.data.user));
            navigate(`../dashboard`);
        }
    };

    return (
        <section className="flex w-full h-full justify-center items-center bg-[#EAB4A9]">
            <div className="flex flex-col justify-center items-center">
                <Card className="p-0.5 !rounded-full w-28 z-10">
                    <img src={Logo} alt="logo" />
                </Card>
                <Card className="pt-16 pb-12 px-12 -mt-14">
                    <h2 className="text-center text-4xl text-primary-900">
                        <strong>Bentornato!</strong>
                    </h2>

                    <form
                        className="mt-12 space-y-6 w-[531px]"
                        onSubmit={handleSubmit(onSubmitHandler)}>
                        <div className="space-y-2">
                            <div
                                className={`form-controller-vertical-label ${
                                    errors?.email ? 'error-input' : ''
                                }`}>
                                <input
                                    type="text"
                                    placeholder="Email"
                                    {...register('email', { required: true })}
                                />
                                {errors?.email?.type === 'required' && (
                                    <span>Email è obbligatorio</span>
                                )}
                            </div>
                            <div
                                className={`form-controller-vertical-label ${
                                    errors?.password ? 'error-input' : ''
                                }`}>
                                <input
                                    type="password"
                                    placeholder="Password"
                                    {...register('password', { required: true })}
                                />
                                {errors?.password?.type === 'required' && (
                                    <span>Password è obbligatorio</span>
                                )}
                            </div>
                        </div>

                        <UserBtn className="w-full" type="submit">
                            ACCEDI
                        </UserBtn>

                        {isEmailSent ? (
                            <p>
                                Ti confermiamo di aver reimpostato la password. All’indirizzo
                                (email) abbiamo inviato una password temporanea che in seguito
                                potrai modificare.
                            </p>
                        ) : (
                            <p className="text-center">
                                Hai dimenticato la password?{' '}
                                <strong
                                    className="cursor-pointer"
                                    onClick={sendEmail}
                                    aria-hidden="true">
                                    Premi qui
                                </strong>
                            </p>
                        )}
                    </form>
                </Card>
            </div>
        </section>
    );
}

export default Login;
