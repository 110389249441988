/* eslint-disable react/prop-types */
import React from 'react';
import { getCurrentYear } from '../helper/utility';

import image1 from '../assets/images/logo.png';
import fb from '../assets/Icons/fb.png';
import insta from '../assets/Icons/insta.png';
import youtube from '../assets/Icons/youtube.png';
import linkedin from '../assets/Icons/linkedin.png';
import mail from '../assets/Icons/mail.png';
import pin from '../assets/Icons/pin.png';
import phone from '../assets/Icons/phone.png';
import PolicyDOC from '../assets/doc/Privacy Policy.docx';
import TerminiDOC from '../assets/doc/Termini di utilizzo.docx';
import NoteDOC from '../assets/doc/Note legali.docx';
import CondizioniDOC from '../assets/doc/Condizioni di acquisto.docx';

function UserFooter() {
    return (
        <footer className="w-full min-h-[180px] pt-6 bg-primary-900 text-center text-[.6rem] text-white mt-auto">
            <div className="w-full max-w-7xl mx-auto">
                <section className="flex justify-around mb-4">
                    <div className="flex flex-col items-center justify-between">
                        <img className="w-16" alt="logo" src={image1} />
                        <div className="flex justify-between w-24 mt-4">
                            <img className="h-4" alt="logo" src={fb} />
                            <img className="h-4" alt="logo" src={insta} />
                            <img className="h-4" alt="logo" src={youtube} />
                            <img className="h-4" alt="logo" src={linkedin} />
                        </div>
                    </div>
                    <div>
                        <ul className="flex flex-col justify-between text-right h-full">
                            <li className="flex justify-center">
                                <img className="h-3 mr-2" alt="logo" src={phone} />
                                02 36591069
                            </li>
                            <li className="flex justify-center">
                                <img className="h-3 mr-2" alt="logo" src={pin} />
                                Viale Piave 28, 20129 Milano
                            </li>
                            <li className="flex justify-center">
                                <img className="h-3 mr-2" alt="logo" src={mail} />{' '}
                                segreteria@winningwomeninstitute.org
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className="flex flex-col justify-between text-right h-full">
                            <li className="flex justify-center">
                                <strong>Informazioni Legali</strong>
                            </li>
                            <li className="flex justify-center">
                                <a href={TerminiDOC} target="_blank" rel="noreferrer">
                                    Termini di utilizzo
                                </a>
                            </li>
                            <li className="flex justify-center">
                                <a href={PolicyDOC} target="_blank" rel="noreferrer">
                                    Privacy Policy
                                </a>
                            </li>
                            <li className="flex justify-center">
                                <a href={NoteDOC} target="_blank" rel="noreferrer">
                                    Note legali
                                </a>
                            </li>
                            <li className="flex justify-center">
                                <a href={CondizioniDOC} target="_blank" rel="noreferrer">
                                    Condizioni di acquisto
                                </a>
                            </li>
                        </ul>
                    </div>
                </section>
                <section>
                    Copyright @ 2016-{getCurrentYear()} - Tutti i diritti riservati -
                    <a href={PolicyDOC} target="_blank" rel="noreferrer">
                        <u>Privacy Policy</u>
                    </a>
                    Design by <strong>Swifty srl</strong>
                </section>
            </div>
        </footer>
    );
}

export default UserFooter;
