import React from 'react';
import { useNavigate } from 'react-router-dom';

import UserBtn from '../component/UserBtn';
import UserLayout from '../layouts/UserLayout';

import messageSend from '../assets/images/send-message.png';

import data from '../assets/data/data.json';

function OnlinePayment() {
    const navigate = useNavigate();

    const goToHomeBtnOnClickHandler = () => {
        navigate('/');
    };

    return (
        <UserLayout title="" subtitle="WINNING WOMAN INSTITUTE">
            <section className="text-center text-2xl">
                <div className="mb-12">
                    <img src={messageSend} alt="successo" className="w-64 mx-auto" />
                </div>
                <p>
                    <strong className="text-4xl">{data.onlinePayment.title}</strong>
                </p>

                <p className="my-6 text-2xl">{data.onlinePayment.paragraph}</p>

                <UserBtn className="my-12 block mx-auto" onClick={goToHomeBtnOnClickHandler}>
                    TORNA ALLA HOME
                </UserBtn>
            </section>
        </UserLayout>
    );
}

export default OnlinePayment;
