import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import Edit from '../../../assets/Icons/edit.png';
import Save from '../../../assets/Icons/save.png';

import Card from '../../../component/Card';
import DashboardPageTitle from '../../../component/DashboardPageTitle';
import DataTable from '../../../component/DataTable';
import { MESSAGES } from '../../../constants';
import { getPlanes, updatePlaneCost } from '../../../helper/services/apis/plane-services';

const HEADERS_DATA = [
    {
        className: '',
        template: <span>Tipologia</span>
    },
    {
        className: '',
        template: <span>Condizione</span>
    },
    {
        className: '',
        template: <span>Costo</span>
    },
    {
        className: 'min-w-[80px]',
        template: ''
    }
];

function Plans() {
    const [planesData, setPlanesData] = useState([]);

    const initPlanes = async () => {
        try {
            const result = await getPlanes();
            if (result && result.ok) {
                setPlanesData([...result.data.map((item) => ({ ...item, isEdit: false }))]);
            } else {
                setPlanesData([]);
            }
        } catch (error) {
            console.log(MESSAGES.SERVER_ERROR);
        }
    };

    const costFieldOnChangeHandler = (event, index) => {
        const planes = [...planesData];
        planes[index].cost = event.target.value;
        setPlanesData([...planesData]);
    };

    const saveBtnOnClickHandler = async (index) => {
        const selectedPlane = planesData[index];
        try {
            await updatePlaneCost({ cost: selectedPlane.cost }, selectedPlane.id);
            toast.success(MESSAGES.SUCCESS);
            initPlanes();
        } catch (error) {
            toast.error(MESSAGES.SERVER_ERROR);
        }
    };

    const editBtnOnClickHandler = (index) => {
        const planes = [...planesData];
        planes[index].isEdit = true;
        setPlanesData([...planesData]);
    };

    const getPlanesRows = () => {
        return planesData.map((plane, index) => ({
            className: '',
            cells: [
                { template: <span>{plane?.name}</span> },
                { template: <span>{plane?.maxEmployeeLimit} dipendenti</span> },
                {
                    template: plane.isEdit ? (
                        <div className="form-controller-vertical-label relative w-28 mx-auto">
                            <input
                                type="number"
                                placeholder=""
                                value={plane.cost}
                                onChange={(e) => costFieldOnChangeHandler(e, index)}
                            />
                            <span className="absolute top-2 right-3 text-gray-400">€</span>
                        </div>
                    ) : (
                        <strong className="text-2xl"> {plane.cost} € </strong>
                    )
                },
                {
                    className: 'flex justify-end',
                    template: plane.isEdit ? (
                        <span onClick={() => saveBtnOnClickHandler(index)} aria-hidden="true">
                            <img alt="save" src={Save} className="h-6 cursor-pointer" />
                        </span>
                    ) : (
                        <span onClick={() => editBtnOnClickHandler(index)} aria-hidden="true">
                            <img alt="edit" src={Edit} className="h-6 cursor-pointer" />
                        </span>
                    )
                }
            ]
        }));
    };

    useEffect(() => {
        initPlanes();
    }, []);

    return (
        <section className="space-y-6">
            <DashboardPageTitle className="text-4xl">Acquisti</DashboardPageTitle>

            <Card className="h-full p-6">
                <DataTable
                    columns={HEADERS_DATA}
                    rows={getPlanesRows()}
                    className="w-full text-center text-base"
                    headerClassName="text-lg border-b border-black"
                    rowClassName="border-b border-border"
                />
            </Card>
        </section>
    );
}

export default Plans;
