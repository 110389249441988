import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import Glass from '../../../assets/Icons/glass.png';
import DownArrow from '../../../assets/Icons/down-arrow.png';
import UpArrow from '../../../assets/Icons/up-arrow.png';
import RightArrow from '../../../assets/Icons/right-arrow.png';

import Card from '../../../component/Card';
import DashboardPageTitle from '../../../component/DashboardPageTitle';
import DataTable from '../../../component/DataTable';
import { getBusinessesHasPaymentWithPagination } from '../../../helper/services/apis/business-services';
import { getPaginateData } from '../../../helper/utility';

const HEADERS_DATA = [
    {
        className: '',
        template: <span>Ragione sociale</span>
    },
    {
        className: '',
        template: <span>Data</span>
    },
    {
        className: '',
        template: <span>Pagamento</span>
    },
    {
        className: '',
        template: <span>Stato pagamento</span>
    },
    {
        className: '',
        template: <span>Risultato in %</span>
    },
    {
        className: '',
        template: <span>Risultato</span>
    },
    {
        className: 'min-w-[80px]',
        template: ''
    }
];

function EvaluationListing() {
    const [rowData, setRowData] = useState([]);
    const [showFilters, setShowFilters] = useState(false);

    const [paginateData, setPaginateData] = useState(null);
    const [selectPaymentType, setSelectPaymentType] = useState('');
    const [selectPaymentStatus, setSelectPaymentStatus] = useState('');
    const [searchString, setSearchString] = useState('');
    const [queryString, setQueryString] = useState('');
    const [debounced, setDebounced] = useState(() => queryString);

    const showFilterOnClickHandler = () => {
        setShowFilters(!showFilters);
    };

    const initBusinesses = async (qs = '') => {
        const result = await getBusinessesHasPaymentWithPagination(qs).catch((e) => {
            console.error(e);
            setRowData([]);
        });

        if (result && result.ok) {
            const mapedData = result.data.data.map((business) => {
                return {
                    className: '',
                    cells: [
                        { template: <span>{business.name}</span> },
                        { template: <span>{business?.result?.createdAt || '-'}</span> },
                        {
                            template: (
                                <span>
                                    {business?.payment?.paymentType === 'Card'
                                        ? 'Carta di credito'
                                        : 'Bonifico'}
                                </span>
                            )
                        },
                        {
                            template: (
                                <span
                                    className={
                                        business?.payment?.status === 'Inprogress'
                                            ? 'text-red-600'
                                            : ''
                                    }>
                                    {business?.payment?.status === 'Inprogress'
                                        ? 'In sospeso'
                                        : 'Avvenuto'}
                                </span>
                            )
                        },
                        {
                            template: (
                                <span>
                                    {business.result
                                        ? `${Math.round(
                                              (business.result.score * 100) /
                                                  business.result.questionTotalPoints
                                          )}%`
                                        : '-'}
                                </span>
                            )
                        },
                        {
                            template: (
                                <span>
                                    {business.result
                                        ? `${business.result.score}/${business.result.questionTotalPoints}`
                                        : '-'}
                                </span>
                            )
                        },
                        {
                            template: (
                                <Link to={`../evaluation/${business.id}`}>
                                    <img
                                        src={RightArrow}
                                        alt="complete"
                                        className="h-4 mx-auto cursor-pointer"
                                    />
                                </Link>
                            )
                        }
                    ]
                };
            });

            setRowData([...mapedData]);
            setPaginateData({ ...getPaginateData(result) });
        }
    };

    const setSelectPaymentTypeChangeHandler = (event) => {
        setSelectPaymentType(event.target.value);
    };
    const setSelectPaymentStatusChangeHandler = (event) => {
        setSelectPaymentStatus(event.target.value);
    };

    const handlePagination = (queryParams) => {
        const qs = queryString + queryParams;
        initBusinesses(qs);
    };

    const searchStringOnChange = (event) => {
        setSearchString(event.target.value);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebounced(searchString);
        }, 1000);
        return () => {
            clearTimeout(timeout);
        };
    }, [searchString]);

    useEffect(() => {
        let qs = '';
        if (debounced) {
            qs = `${qs}searchString=${debounced}&`;
        }
        if (selectPaymentType) {
            qs = `${qs}selectPaymentType=${selectPaymentType}&`;
        }
        if (selectPaymentStatus) {
            qs = `${qs}selectPaymentStatus=${selectPaymentStatus}&`;
        }
        setQueryString(qs);
    }, [selectPaymentType, selectPaymentStatus, debounced]);

    useEffect(() => {
        initBusinesses(queryString);
    }, [queryString]);

    useEffect(() => {
        if (showFilters) {
            setSearchString('');
        } else {
            setSelectPaymentType('');
            setSelectPaymentStatus('');
        }
    }, [showFilters]);

    return (
        <section className="space-y-6">
            <DashboardPageTitle className="text-4xl">Valutazioni</DashboardPageTitle>

            <div className="flex space-x-4">
                <Card className="form-controller-horizontal-label w-11/12">
                    <input
                        type="text"
                        className="!pl-9"
                        placeholder="Cerca..."
                        value={searchString}
                        onChange={searchStringOnChange}
                    />
                    <span>
                        <img alt="find" className="w-5 absolute bottom-3 left-3" src={Glass} />
                    </span>
                </Card>
                <Card
                    className="flex justify-between w-2/12 py-2 px-3 items-center cursor-pointer"
                    onClick={showFilterOnClickHandler}>
                    <span>Filtri</span>
                    <img alt="find" className="h-5" src={showFilters ? UpArrow : DownArrow} />
                </Card>
            </div>

            {showFilters ? (
                <div className="flex space-x-4">
                    <Card>
                        <select
                            id="countries"
                            value={selectPaymentType}
                            onChange={setSelectPaymentTypeChangeHandler}>
                            <option value="">Seleziona pagamento</option>
                            <option value="Card">Carta di credito</option>
                            <option value="Banking">Bonifico</option>
                        </select>
                    </Card>

                    <Card>
                        <select
                            id="countries"
                            value={selectPaymentStatus}
                            onChange={setSelectPaymentStatusChangeHandler}>
                            <option value="">Seleziona stato pagamento</option>
                            <option value="Completed">Avvenuto</option>
                            <option value="Inprogress">In sospeso</option>
                        </select>
                    </Card>
                </div>
            ) : null}

            <Card className="h-full p-6">
                <DataTable
                    columns={HEADERS_DATA}
                    rows={rowData}
                    className="w-full text-center text-base"
                    headerClassName="text-lg border-b border-black"
                    rowClassName="border-b border-border"
                    paginateData={paginateData}
                    handlePagination={handlePagination}
                />
            </Card>
        </section>
    );
}

export default EvaluationListing;
