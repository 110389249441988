/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';

export const checkIsInMaintenance = async () => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.CHECK_STATUS}`);
    return result;
};

export const updateIsInMaintenanceStatus = async (payload) => {
    const result = await axiosInstance.put(`v1/${API_ENDPOINT.CHECK_STATUS}`, payload);
    return result;
};
