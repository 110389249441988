/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import UserLayout from '../layouts/UserLayout';
import data from '../assets/data/data.json';
import UserBtn from '../component/UserBtn';
import { checkIsTokenValidForPayment } from '../helper/services/apis/user-services';
import Loader from '../component/Loader';

function PrivacyPolicy() {
    const params = useParams();
    const navigate = useNavigate();
    const [token, setToken] = useState('');
    const [isLoader, setIsLoader] = useState(true);

    const continueBtnOnClickHandler = () => {
        navigate(`/make-payment/${token}`);
    };

    const validateToken = async (t) => {
        if (t && t.length) {
            const result = await checkIsTokenValidForPayment(t);
            if (result.data.isValid && !result.data.isPayment) {
                setIsLoader(false);
            } else {
                navigate('/');
            }
        }
    };

    useEffect(() => {
        setToken(params.token);
    }, []);

    useEffect(() => {
        validateToken(token);
    }, [token]);

    return isLoader ? (
        <Loader />
    ) : (
        <UserLayout title="" subtitle="ATTENZIONE!">
            <section className="text-center">
                <p className="mb-12">
                    <strong className="text-xl">{data.privacy.title}</strong>
                </p>

                <p className="mb-6">
                    Il presente questionario sui presupposti necessari ad ottenere la Certificazione
                    sulla Parità di Genere seconda la norma nazionale Uni/Pdr <strong>NON</strong>{' '}
                    equivale alla Certificazione.
                </p>

                <p className="mb-12">
                    Trattasi di una esclusiva corretta valutazione dei dati di titolarità
                    dell’Impresa/Azienda ai fini della possibilità di ottenimento della
                    Certificazione (ex lege) che verrà rilasciata dai soggetti autorizzati nel
                    territorio nazionale.
                </p>

                <p>
                    <strong className="underline">
                        <Link to={`/questions-survey/${token}`}>Clicca qui</Link>
                    </strong>{' '}
                    se vuoi vedere in anteprima le domande del questionario.
                </p>

                <UserBtn className="mt-6 mb-12" onClick={continueBtnOnClickHandler}>
                    CONTINUA
                </UserBtn>
            </section>
        </UserLayout>
    );
}

export default PrivacyPolicy;
