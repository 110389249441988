import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';

export const createBusiness = async (payload) => {
    const result = await axiosInstance.post(`v1/${API_ENDPOINT.BUSINESS}`, payload);
    return result;
};

export const getBusinessesWithPagination = async (queryString) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.BUSINESSES}?${queryString}`);
    return result;
};

export const getBusinessesHasPaymentWithPagination = async (queryString) => {
    const result = await axiosInstance.get(
        `v1/${API_ENDPOINT.BUSINESSES_HAS_PAYMENT}?${queryString}`
    );
    return result;
};

export const getBusinessById = async (id) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.BUSINESS}/${id}`);
    return result;
};

export const getBusinessFromToken = async (token) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.TOKEN_BUSINESS}/${token}`);
    return result;
};

export const deleteBusiness = async (id) => {
    const result = await axiosInstance.delete(`v1/${API_ENDPOINT.BUSINESS}/${id}`);
    return result;
};
