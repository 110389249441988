import React from 'react';
import { useLocation, Outlet, Link } from 'react-router-dom';
import './dashboard.css';

import Logo from '../../../assets/images/logo.png';
import activeClient from '../../../assets/Icons/client-2.png';
import inActiveClient from '../../../assets/Icons/client-1.png';
import inActiveValutazioni from '../../../assets/Icons/valutazioni-1.png';
import activeValutazioni from '../../../assets/Icons/valutazioni-2.png';
import inActiveDomande from '../../../assets/Icons/domande-1.png';
import activeDomande from '../../../assets/Icons/domande-2.png';
import inActiveAcquisti from '../../../assets/Icons/acquisti-1.png';
import activeAcquisti from '../../../assets/Icons/acquisti-2.png';
import gear from '../../../assets/Icons/gear.png';
import logout from '../../../assets/Icons/logout.png';
import { logoutProcess } from '../../../helper/utility';

function Dashboard() {
    const location = useLocation();

    const checkIsActive = (path) => {
        return location.pathname.includes(path);
    };

    const logoutBtnOnPressHandler = () => {
        logoutProcess();
    };

    return (
        <div className="dashboard">
            <header className="flex justify-between dashboard--header bg-primary-900 items-center">
                <div className="px-6 py-1.5 h-16">
                    <img className="h-full" src={Logo} alt="logo" />
                </div>
                <div className="text-white text-2xl">WINNING WOMAN INSTITUTE</div>
                <div className="flex space-x-2">
                    <div className="mt-2">
                        <Link to="profile">
                            <img src={gear} alt="Setting" className="pb-2 px-3.5 h-11" />
                        </Link>
                    </div>
                    <div className="mt-2">
                        <img
                            src={logout}
                            alt="Setting"
                            className="pb-2 px-3.5 h-11 cursor-pointer"
                            onClick={logoutBtnOnPressHandler}
                            aria-hidden="true"
                        />
                    </div>
                </div>
            </header>
            <aside className="dashboard--nav bg-white text-[#A4A4A4]">
                <nav>
                    <ul className="px-3 py-4 divide-y">
                        <li className="py-4 text-center cursor-pointer">
                            <Link to="clients">
                                <img
                                    src={checkIsActive('clients') ? activeClient : inActiveClient}
                                    alt="Clienti"
                                    className="pb-2 px-3.5"
                                />
                                <span className="text-xs hover:text-primary-900">Clienti</span>
                            </Link>
                        </li>
                        <li className="py-4 text-center cursor-pointer">
                            <Link to="evaluations">
                                <img
                                    src={
                                        checkIsActive('evaluations')
                                            ? activeValutazioni
                                            : inActiveValutazioni
                                    }
                                    alt="Valutazioni"
                                    className="pb-2 px-3.5"
                                />
                                <span className="text-xs hover:text-primary-900">Valutazioni</span>
                            </Link>
                        </li>
                        <li className="py-4 text-center cursor-pointer">
                            <Link to="questions">
                                <img
                                    src={
                                        checkIsActive('questions') ? activeDomande : inActiveDomande
                                    }
                                    alt="Domande"
                                    className="pb-2 px-3.5"
                                />
                                <span className="text-xs hover:text-primary-900">Domande</span>
                            </Link>
                        </li>
                        <li className="py-4 text-center cursor-pointer">
                            <Link to="plans">
                                <img
                                    src={checkIsActive('plans') ? activeAcquisti : inActiveAcquisti}
                                    alt="Acquisti"
                                    className="pb-2 px-3.5"
                                />
                                <span className="text-xs hover:text-primary-900">Acquisti</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </aside>
            <main className="dashboard--main bg-[#F5F3F1] text-[#575757] px-6 py-8">
                <div className="max-w-7xl mx-auto">
                    <Outlet />
                </div>
            </main>
        </div>
    );
}

export default Dashboard;
