/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import BinIcon from '../../../assets/Icons/bin.png';
import CloneIcon from '../../../assets/Icons/clone.png';
import EditIcon from '../../../assets/Icons/edit.png';

import Card from '../../../component/Card';
import DashboardPageTitle from '../../../component/DashboardPageTitle';
import DataTable from '../../../component/DataTable';
import ModalComponent from '../../../component/modal/ModalComponent';
import UserBtn from '../../../component/UserBtn';
import { MESSAGES } from '../../../constants';
import { getBusinessesCategories } from '../../../helper/services/apis/business-category-services';
import { getPlanes } from '../../../helper/services/apis/plane-services';
import {
    createQuestion,
    deleteQuestion,
    getQuestions,
    updateQuestion
} from '../../../helper/services/apis/question-services';
import { getPaginateData } from '../../../helper/utility';

const HEADERS_DATA = [
    {
        className: '',
        template: <span>N°</span>
    },
    {
        className: 'min-w-[15rem]',
        template: <span>Domanda</span>
    },
    {
        className: 'min-w-[180px]',
        template: <span>Tipologia</span>
    },
    {
        className: 'min-w-[15rem]',
        template: <span>Modalità di misurazione</span>
    },
    {
        className: 'min-w-[15rem]',
        template: <span>Fonte</span>
    },
    {
        className: '',
        template: <span>Condizione</span>
    },
    {
        className: '',
        template: <span>Punti</span>
    },
    {
        className: '',
        template: <span>Fascia 1</span>
    },
    {
        className: '',
        template: <span>Fascia 2</span>
    },
    {
        className: '',
        template: <span>Fascia 3</span>
    },
    {
        className: '',
        template: <span>Fascia 4</span>
    },
    {
        className: 'min-w-[180px]',
        template: <span>Codici Ateco</span>
    },
    {
        className: 'min-w-[80px]',
        template: ''
    }
];

function Questions() {
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm();
    const questionType = watch('questionType', false);

    const [isOpen, setIsOpen] = useState(false);

    const [businessCategories, setBusinessCategory] = useState([]);
    const [planes, setPlanes] = useState([]);

    const [questions, setQuestions] = useState([]);
    const [paginateData, setPaginateData] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const onModalCloseBtnClickHandler = () => {
        setIsOpen(false);
    };

    const initQuestionsListing = async (queryString = '') => {
        const result = await getQuestions(queryString).catch((e) => {
            console.log(e);
        });

        const rowData = result.data.data.map((question) => {
            return { ...question, planesId: question.planes.map((plane) => plane.id.toString()) };
        });
        setQuestions([...rowData]);
        setPaginateData({ ...getPaginateData(result) });
    };

    const initBusinessCategory = async () => {
        const categories = await getBusinessesCategories().catch((e) => {
            console.error(e);
            setBusinessCategory([]);
        });

        setBusinessCategory([...categories.data]);
    };

    const initPlanse = async () => {
        const result = await getPlanes().catch((e) => {
            setPlanes([]);
            console.error(e);
        });

        setPlanes([...result.data]);
    };

    const onSubmitHandler = async (data) => {
        if (isEdit) {
            const result = await updateQuestion(data, data.id).catch((e) => {
                console.error(e);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            if (result && result.ok) {
                initQuestionsListing();
                toast.success(MESSAGES.SUCCESS);
                setIsOpen(false);
            }
        } else {
            const result = await createQuestion(data).catch((e) => {
                console.error(e);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            if (result && result.ok) {
                initQuestionsListing();
                toast.success(MESSAGES.SUCCESS);
                setIsOpen(false);
            }
        }
    };

    const createNewBtnOnClickHandler = () => {
        setIsOpen(true);
    };

    const cancelBtnOnClickHandler = () => {
        setIsOpen(false);
    };

    const deleteSelectedQuestion = async (questionId) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Sei sicuro di voler rimuovere la domanda?')) {
            try {
                const result = await deleteQuestion(questionId);
                if (result && result.ok) {
                    toast.success('Removed.');
                    initQuestionsListing();
                }
            } catch (error) {
                toast.error(MESSAGES.SERVER_ERROR);
            }
        }
    };

    const onActionClickHandler = (question, action) => {
        switch (action) {
            case 'edit':
                setIsEdit(true);
                reset({
                    ...question
                });
                setIsOpen(true);
                break;
            case 'delete':
                deleteSelectedQuestion(question.id);
                break;
            case 'clone':
                reset({
                    ...question
                });
                setIsOpen(true);
                break;
            default:
                break;
        }
    };

    const getRowsData = (rows, startIndex = 1) => {
        return rows.map((question, index) => ({
            className: '',
            cells: [
                {
                    template: <span>{startIndex + index}</span>
                },
                {
                    template: <span>{question.question}</span>
                },
                {
                    template: (
                        <span>
                            {question.questionType === 'Boolean' ? 'Qualitativo' : 'Quantitativo'}
                        </span>
                    )
                },
                {
                    template: <span>{question.measurementMode}</span>
                },
                {
                    template: <span>{question.source}</span>
                },
                {
                    template: <span>{question.minimumPercentage} % </span>
                },
                {
                    template: <span>{question.questionPoints}</span>
                },
                {
                    template: (
                        <input
                            type="checkbox"
                            checked={question?.planesId?.includes('1')}
                            disabled
                        />
                    )
                },
                {
                    template: (
                        <input
                            type="checkbox"
                            checked={question?.planesId?.includes('2')}
                            disabled
                        />
                    )
                },
                {
                    template: (
                        <input
                            type="checkbox"
                            checked={question?.planesId?.includes('3')}
                            disabled
                        />
                    )
                },
                {
                    template: (
                        <input
                            type="checkbox"
                            checked={question?.planesId?.includes('4')}
                            disabled
                        />
                    )
                },
                {
                    template: <span>{question.businessCategory.code}</span>
                },
                {
                    template: (
                        <ul>
                            <li className="p-2">
                                <img
                                    src={EditIcon}
                                    alt="complete"
                                    className="h-4 mx-auto cursor-pointer"
                                    onClick={() => onActionClickHandler(question, 'edit')}
                                    aria-hidden="true"
                                />
                            </li>
                            <li className="p-2">
                                <img
                                    src={BinIcon}
                                    alt="complete"
                                    className="h-4 mx-auto cursor-pointer"
                                    onClick={() => onActionClickHandler(question, 'delete')}
                                    aria-hidden="true"
                                />
                            </li>
                            <li className="p-2">
                                <img
                                    src={CloneIcon}
                                    alt="complete"
                                    className="h-4 mx-auto cursor-pointer"
                                    onClick={() => onActionClickHandler(question, 'clone')}
                                    aria-hidden="true"
                                />
                            </li>
                        </ul>
                    )
                }
            ]
        }));
    };

    const handlePagination = (queryString) => {
        initQuestionsListing(queryString);
    };
    useEffect(() => {
        if (!isOpen) {
            reset({
                question: '',
                questionType: 'Boolean',
                businessCategoryId: '',
                measurementMode: '',
                source: '',
                planesId: [],
                minimumPercentage: 0,
                questionPoints: 0
            });
            setIsEdit(false);
        }
    }, [isOpen]);

    useEffect(() => {
        initQuestionsListing();
        initBusinessCategory();
        initPlanse();
    }, []);

    return (
        <>
            <ModalComponent isOpen={isOpen} onClose={onModalCloseBtnClickHandler}>
                {/* Modal header  */}
                <div className="py-9 px-6 rounded-t border-b text-center">
                    <h1 className="text-4xl font-semibold text-primary-900">
                        {isEdit ? 'Modifica domanda' : 'Aggiungi domanda'}
                    </h1>
                </div>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    {/* Modal body  */}
                    <div className="flex flex-wrap">
                        <div
                            className={`w-full px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.question ? 'error-input' : ''
                            }`}>
                            <label htmlFor="domanda"> Domanda* </label>
                            <textarea
                                {...register('question', { required: true, maxLength: 500 })}
                            />
                            {errors?.question?.type === 'required' && (
                                <span>Domanda è obbligatorio</span>
                            )}
                            {errors?.question?.type === 'maxLength' && (
                                <span>Questo campo non può superare i 500 caratteri</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.questionType ? 'error-input' : ''
                            }`}>
                            <label htmlFor="tipologia"> TIPOLOGIA* </label>
                            <select
                                id="tipologia"
                                {...register('questionType', { required: true })}>
                                <option value="Boolean">Qualitativo</option>
                                <option value="Numeric">Quantitativo</option>
                            </select>
                            {errors?.questionType?.type === 'required' && (
                                <span>Tipologia è obbligatorio</span>
                            )}
                        </div>
                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.businessCategoryId ? 'error-input' : ''
                            }`}>
                            <label htmlFor="businessCategoryId"> CODICI ATECO* </label>
                            <select {...register('businessCategoryId', { required: true })}>
                                <option value="">Seleziona Codice Ateco</option>
                                {businessCategories.map((category) => (
                                    <option value={category.id} key={category.id}>
                                        {category.code}
                                    </option>
                                ))}
                            </select>
                            {errors?.businessCategoryId?.type === 'required' && (
                                <span>Codici Ateco è obbligatorio</span>
                            )}
                        </div>

                        <div
                            className={`w-full px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.measurementMode ? 'error-input' : ''
                            }`}>
                            <label htmlFor="measurementMode"> MODALITÀ DI MISURAZIONE* </label>
                            <textarea
                                id="measurementMode"
                                {...register('measurementMode', { required: true, maxLength: 255 })}
                            />
                            {errors?.measurementMode?.type === 'required' && (
                                <span>Modalità di misurazione è obbligatorio</span>
                            )}
                            {errors?.measurementMode?.type === 'maxLength' && (
                                <span>Questo campo non può superare i 255 caratteri</span>
                            )}
                        </div>

                        <div
                            className={`w-full px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.source ? 'error-input' : ''
                            }`}>
                            <label htmlFor="source"> FONTE* </label>
                            <textarea
                                id="source"
                                {...register('source', { required: true, maxLength: 255 })}
                            />
                            {errors?.source?.type === 'required' && (
                                <span>Fonte è obbligatorio</span>
                            )}
                            {errors?.source?.type === 'maxLength' && (
                                <span>Questo campo non può superare i 255 caratteri</span>
                            )}
                        </div>

                        <div className="form-controller-vertical-label w-full px-6 my-5">
                            <label htmlFor="source"> FASCIA* </label>
                            <div className="flex justify-between">
                                {planes.map((plane) => (
                                    <div key={plane.id}>
                                        <input
                                            {...register('planesId')}
                                            type="checkbox"
                                            className="mr-2"
                                            value={plane.id}
                                        />
                                        {plane.name}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.minimumPercentage ? 'error-input' : ''
                            }`}>
                            <label htmlFor="minimumPercentage"> CONDIZIONE* </label>
                            <div className="relative">
                                <input
                                    id="minimumPercentage"
                                    type="number"
                                    placeholder=""
                                    {...register('minimumPercentage', { required: true })}
                                    disabled={questionType === 'Boolean'}
                                />
                                <span className="absolute top-2 right-3 text-gray-400 text-base">
                                    %
                                </span>
                            </div>
                            {errors?.minimumPercentage?.type === 'required' && (
                                <span>Condizione è obbligatorio</span>
                            )}
                        </div>

                        <div
                            className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                                errors?.questionPoints ? 'error-input' : ''
                            }`}>
                            <label htmlFor="questionPoints"> PUNTI* </label>
                            <input
                                id="questionPoints"
                                type="number"
                                placeholder=""
                                step=".01"
                                {...register('questionPoints', { required: true })}
                            />
                            {errors?.questionPoints?.type === 'required' && (
                                <span>Punti è obbligatorio</span>
                            )}
                        </div>
                    </div>

                    {/* Modal footer  */}
                    <div className="py-9 px-6 rounded-t border-t text-center flex justify-between">
                        <UserBtn onClick={cancelBtnOnClickHandler}>Annulla</UserBtn>
                        <UserBtn type="submit">{isEdit ? 'Modifica' : 'Crea'}</UserBtn>
                        {/* <UserBtn>Aggiornare</UserBtn> */}
                    </div>
                </form>
            </ModalComponent>

            <section className="space-y-6">
                <DashboardPageTitle className="flex w-full text-4xl justify-between">
                    <span>Domande</span>

                    <div className="flex space-x-4">
                        <span className="text-2xl">Score minimo di sintesi :</span>
                        <div className="form-controller-vertical-label relative w-28 mx-auto">
                            <input type="number" placeholder="" value="60" disabled />
                            <span className="absolute top-2 right-3 text-gray-400 text-base">
                                %
                            </span>
                        </div>
                    </div>
                </DashboardPageTitle>

                <Card className="h-full p-6 overflow-auto">
                    <DataTable
                        columns={HEADERS_DATA}
                        rows={getRowsData(questions, paginateData?.from)}
                        className="w-full text-center text-base"
                        headerClassName="text-lg border-b border-black"
                        rowClassName="border-b border-border"
                        paginateData={paginateData}
                        handlePagination={handlePagination}
                    />
                </Card>
                <div className="flex flex-row-reverse w-full my-6">
                    <UserBtn onClick={createNewBtnOnClickHandler}>AGGIUNGI</UserBtn>
                </div>
            </section>
        </>
    );
}

export default Questions;
