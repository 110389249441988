/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';

export const getPlanes = async () => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.PLANES}`);
    return result;
};

export const updatePlaneCost = async (payload, id) => {
    const result = await axiosInstance.put(`v1/${API_ENDPOINT.PLANE}/${id}`, payload);
    return result;
};

export const getUserPlaneAmount = async (token) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.PAY}/${token}`);
    return result;
};
