import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Glass from '../../../assets/Icons/glass.png';
import DownArrow from '../../../assets/Icons/down-arrow.png';
import UpArrow from '../../../assets/Icons/up-arrow.png';
import Correct from '../../../assets/Icons/correct.png';
import RightArrow from '../../../assets/Icons/right-arrow.png';

import Card from '../../../component/Card';
import DashboardPageTitle from '../../../component/DashboardPageTitle';
import DataTable from '../../../component/DataTable';
import { getBusinessesWithPagination } from '../../../helper/services/apis/business-services';
import { getPaginateData } from '../../../helper/utility';
import { getBusinessesCategories } from '../../../helper/services/apis/business-category-services';
import { getPlanes } from '../../../helper/services/apis/plane-services';

const HEADERS_DATA = [
    {
        className: '',
        template: <span>Ragione sociale</span>
    },
    {
        className: '',
        template: <span>Email</span>
    },
    {
        className: '',
        template: <span>Settore</span>
    },
    {
        className: '',
        template: <span>Codice Ateco</span>
    },
    {
        className: '',
        template: <span>Fascia</span>
    },
    {
        className: '',
        template: <span>Acquistato</span>
    },
    {
        className: 'min-w-[80px]',
        template: ''
    }
];

function ClientsListing() {
    const [showFilters, setShowFilters] = useState(false);
    const [rowData, setRowData] = useState([]);

    const [planes, setPlanes] = useState([]);
    const [category, setCategory] = useState([]);

    const [selectIsPayment, setSelectIsPayment] = useState('');
    const [selectedPlane, setSelectedPlane] = useState('');
    const [selectedCode, setSelectedCode] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchString, setSearchString] = useState('');
    const [queryString, setQueryString] = useState('');
    const [debounced, setDebounced] = useState(() => queryString);

    const [paginateData, setPaginateData] = useState(null);

    const showFilterOnClickHandler = () => {
        setShowFilters(!showFilters);
    };

    const initBusinesses = async (qs = '') => {
        const result = await getBusinessesWithPagination(qs).catch((e) => {
            console.error(e);
            setRowData([]);
        });

        if (result && result.ok) {
            const mapedData = result.data.data.map((business) => {
                return {
                    className: '',
                    cells: [
                        { template: <span>{business.name}</span> },
                        {
                            template: (
                                <span>
                                    <a
                                        href={`mailto:Azienda@gmail.com${business.email}`}
                                        className="text-sky-500">
                                        {business.email}
                                    </a>
                                </span>
                            )
                        },
                        { template: <span>{business.businessCategory.title}</span> },
                        { template: <span>{business.businessCategory.code}</span> },
                        { template: <span>{business.plane.name}</span> },
                        {
                            template: (
                                <span>
                                    {business.payment && business.payment.status === 'Completed' ? (
                                        <img src={Correct} alt="complete" className="h-4 mx-auto" />
                                    ) : (
                                        '-'
                                    )}
                                </span>
                            )
                        },
                        {
                            template: (
                                <Link to={`../client/${business.id}`}>
                                    <img
                                        src={RightArrow}
                                        alt="complete"
                                        className="h-4 mx-auto cursor-pointer"
                                    />
                                </Link>
                            )
                        }
                    ]
                };
            });

            setRowData([...mapedData]);
            setPaginateData({ ...getPaginateData(result) });
        }
    };

    const isPaymentOnChangeHandler = (event) => {
        setSelectIsPayment(event.target.value);
    };
    const planeOnChangeHandler = (event) => {
        setSelectedPlane(event.target.value);
    };
    const codeOnChangeHandler = (event) => {
        setSelectedCode(event.target.value);
    };
    const categoryOnChangeHandler = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handlePagination = (queryParams) => {
        const qs = queryString + queryParams;
        initBusinesses(qs);
    };

    const initBusinessCategory = async () => {
        const categories = await getBusinessesCategories().catch((e) => {
            console.error(e);
            setCategory([]);
        });
        const items = [...categories.data];
        const selectedIndex = items.findIndex((item) => item.code === 'Tutti');
        items.splice(selectedIndex, 1);
        setCategory([...items]);
    };

    const initPlanes = async () => {
        try {
            const result = await getPlanes();
            if (result && result.ok) {
                setPlanes([...result.data]);
            } else {
                setPlanes([]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const searchStringOnChange = (event) => {
        setSearchString(event.target.value);
    };

    useEffect(() => {
        initPlanes();
        initBusinessCategory();
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebounced(searchString);
        }, 1000);
        return () => {
            clearTimeout(timeout);
        };
    }, [searchString]);

    useEffect(() => {
        let qs = '';
        if (debounced) {
            qs = `${qs}searchString=${debounced}&`;
        }
        if (selectIsPayment) {
            qs = `${qs}selectIsPayment=${selectIsPayment}&`;
        }
        if (selectedPlane) {
            qs = `${qs}selectedPlane=${selectedPlane}&`;
        }
        if (selectedCode) {
            qs = `${qs}selectedCode=${selectedCode}&`;
        }
        if (selectedCategory) {
            qs = `${qs}selectedCategory=${selectedCategory}&`;
        }
        setQueryString(qs);
    }, [selectIsPayment, selectedPlane, selectedCode, selectedCategory, debounced]);

    useEffect(() => {
        initBusinesses(queryString);
    }, [queryString]);

    useEffect(() => {
        if (showFilters) {
            setSearchString('');
        } else {
            setSelectIsPayment('');
            setSelectedPlane('');
            setSelectedCode('');
            setSelectedCategory('');
        }
    }, [showFilters]);

    return (
        <section className="space-y-6">
            <DashboardPageTitle className="text-4xl">Anagrafica clienti</DashboardPageTitle>

            <div className="flex space-x-4">
                <Card className="form-controller-horizontal-label w-11/12">
                    <input
                        type="text"
                        className="!pl-9"
                        placeholder="Cerca..."
                        value={searchString}
                        onChange={searchStringOnChange}
                    />
                    <span>
                        <img alt="find" className="w-5 absolute bottom-3 left-3" src={Glass} />
                    </span>
                </Card>
                <Card
                    className="flex justify-between w-2/12 py-2 px-3 items-center cursor-pointer"
                    onClick={showFilterOnClickHandler}>
                    <span>Filtri</span>
                    <img alt="find" className="h-5" src={showFilters ? UpArrow : DownArrow} />
                </Card>
            </div>

            {showFilters ? (
                <div className="flex space-x-4">
                    <Card>
                        <select
                            id="countries"
                            value={selectedCategory}
                            onChange={categoryOnChangeHandler}>
                            <option value="">Seleziona Settore</option>
                            {category.map((c) => (
                                <option key={c.id} value={c.id}>
                                    {c.title}
                                </option>
                            ))}
                        </select>
                    </Card>

                    <Card>
                        <select id="countries" value={selectedCode} onChange={codeOnChangeHandler}>
                            <option value="">Seleziona Codice Ateco</option>
                            {category.map((c) => (
                                <option key={c.id} value={c.id}>
                                    {c.code}
                                </option>
                            ))}
                        </select>
                    </Card>

                    <Card>
                        <select
                            id="countries"
                            value={selectedPlane}
                            onChange={planeOnChangeHandler}>
                            <option value="">Seleziona Fascia</option>
                            {planes.map((plane) => (
                                <option key={plane.id} value={plane.id}>
                                    {plane.name}
                                </option>
                            ))}
                        </select>
                    </Card>

                    <Card>
                        <select value={selectIsPayment} onChange={isPaymentOnChangeHandler}>
                            <option value="">Seleziona Acquistato</option>
                            <option value="true">Acquistato</option>
                            <option value="false">Non Acquistato</option>
                        </select>
                    </Card>
                </div>
            ) : null}

            <Card className="h-full p-6">
                <DataTable
                    columns={HEADERS_DATA}
                    rows={rowData}
                    className="w-full text-center text-base"
                    headerClassName="text-lg border-b border-black"
                    rowClassName="border-b border-border"
                    paginateData={paginateData}
                    handlePagination={handlePagination}
                />
            </Card>
        </section>
    );
}

export default ClientsListing;
