export const API_ENDPOINT = {
    BUSINESS: 'business',
    BUSINESSES: 'businesses',
    BUSINESSES_HAS_PAYMENT: 'businesses-has-payment',
    VALIDATE_TOKEN: 'validate-token',
    PAY: 'pay-amount',
    PLANE: 'plane',
    PLANES: 'planes',
    QUESTIONO: 'question',
    QUESTIONOS: 'questions',
    PLANE_QUESTIONS: 'plane-questions',
    TOKEN_BUSINESS: 'token-business',
    PAYMENT: 'payment',
    LOGIN: 'auth/login',
    SURVEY: 'surveys',
    SEND_LINK: 'survey-send-link',
    DOWNLOAD_PDF: 'download-pdf',
    UPDATE_STATUS: 'update-status',
    CHECK_STATUS: 'app-status'
};

export const MESSAGES = {
    SUCCESS: 'Successo',
    SERVER_ERROR: 'Errore del server'
};

export const PHONE_REGEX =
    /^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$/gm;

export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}/g;

// Valid Examples :  00199, V-00120, I-00120
export const CAP_REGEX = /^(V-|I-)?[0-9]{5}$/;

export const NUMBER_REGEX = /[^\d]+/gim;

export const TWO_DECIMAL_NUMBER_REGEX = /[^\d]+/gim;
