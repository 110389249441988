/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import Edit from '../../../assets/Icons/edit.png';
import Save from '../../../assets/Icons/save.png';

import Card from '../../../component/Card';
import QuestionListingComponent from '../../../component/QuestionComponent';
import { getBusinessById } from '../../../helper/services/apis/business-services';
import { API_ENDPOINT } from '../../../constants';
import BusinessDetails from '../../../component/Layout/BusinessDetails';
import { updatePaymentStatus } from '../../../helper/services/apis/payment-services';
import { getEnvironmentKeyValue } from '../../../helper/utility';

function Evaluation() {
    const params = useParams();
    const [business, setBusiness] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const deleteModalData = {
        title: 'Vuoi eliminare la valutazione?',
        body: 'Attenzione! Una volta eliminato il record non sarà più possibile recuperarlo. Continuare?'
    };

    const initBusiness = async () => {
        // eslint-disable-next-line no-unused-vars
        const result = await getBusinessById(params.id).catch((e) => {
            setBusiness(null);
        });

        if (result && result.ok) {
            setBusiness({ ...result.data });
        }
    };

    const copyLink = () => {
        const url = `${getEnvironmentKeyValue('BASE_URL')}fill-survey/${business?.token}`;
        navigator.clipboard.writeText(url);
    };

    const downloadPdf = async (businessId) => {
        window.open(
            `${getEnvironmentKeyValue('API_BASE_URL')}/v1/${
                API_ENDPOINT.DOWNLOAD_PDF
            }/${businessId}`,
            '_blank'
        );
    };

    const getRows = (data) => {
        return data.map((item, index) => ({
            className: '',
            cells: [
                { template: <strong>{index + 1}.</strong> },
                {
                    template: <span>{item.question}</span>
                },
                {
                    className: 'flex justify-end',
                    template: <span>{item.answer}</span>
                }
            ]
        }));
    };

    const toggleEdit = async (currentState, paymentId, paymentStatus) => {
        if (currentState) {
            const result = await updatePaymentStatus(paymentId, { status: paymentStatus });
            if (result && result.ok) {
                initBusiness();
                toast.success('Updated');
            } else {
                toast.error('Error');
            }
        }
        setIsEdit(!isEdit);
    };

    const toggleStatusOnChangeHandler = (event, currentBusiness) => {
        const newBusinessData = { ...currentBusiness };
        newBusinessData.payment = { ...newBusinessData.payment };
        newBusinessData.payment.status = event.target.value;
        setBusiness({ ...newBusinessData });
    };

    useEffect(() => {
        initBusiness();
    }, []);

    return (
        <BusinessDetails
            businessId={business?.id}
            deleteModalData={deleteModalData}
            businessName={business?.name}>
            <Card className="space-y-24 p-6 pb-24">
                <section className="space-y-12">
                    {/* title */}
                    <div className="flex justify-between pb-6 border-b">
                        <h2 className="text-2xl">
                            <strong>Contatti</strong>
                        </h2>
                        {business?.payment?.paymentType !== 'Card' ? (
                            <span
                                onClick={(e) =>
                                    toggleEdit(
                                        isEdit,
                                        business?.payment.id,
                                        business?.payment.status
                                    )
                                }
                                aria-hidden="true">
                                <img
                                    src={isEdit ? Save : Edit}
                                    alt="Edit"
                                    className="inline-block h-6 cursor-pointer pr-4"
                                />
                            </span>
                        ) : null}
                    </div>

                    <div className="flex flex-wrap justify-around">
                        <span>
                            <strong>Referente : </strong>
                            {business?.contactName}
                        </span>
                        <span>
                            <strong>Email : </strong>
                            <a href={`mailto:${business?.email}`} className="text-sky-500">
                                {business?.email}
                            </a>
                        </span>
                        <span>
                            <strong>Telefono : </strong>
                            {business?.telephoneNumber}
                        </span>
                        <span>
                            <strong>P.IVA : </strong>
                            {business?.vatNumber}
                        </span>
                    </div>
                </section>

                <section className="space-y-12">
                    {/* title */}
                    <div className="pb-6 border-b">
                        <h2 className="text-2xl">
                            <strong>Valutazione</strong>
                        </h2>
                    </div>

                    <div className="flex flex-wrap justify-around items-center">
                        <span>
                            <strong>Data valutazione : </strong>
                            {business?.result ? business?.result?.createdAt || '-' : '-'}
                        </span>
                        <span>
                            <strong>Pagamento : </strong>
                            {business?.payment
                                ? business?.payment?.paymentType === 'Card'
                                    ? 'Carta di credito'
                                    : 'Bonifico'
                                : '-'}
                        </span>
                        <span className="flex items-center">
                            <strong>Stato pagamento : </strong>
                            {isEdit ? (
                                <select
                                    value={business?.payment?.status}
                                    onChange={(e) => toggleStatusOnChangeHandler(e, business)}
                                    className="w-[150px]">
                                    <option value="Inprogress">In sospeso</option>
                                    <option value="Completed">Avvenuto</option>
                                </select>
                            ) : business?.payment ? (
                                business?.payment?.status === 'Inprogress' ? (
                                    'In sospeso'
                                ) : (
                                    'Avvenuto'
                                )
                            ) : (
                                '-'
                            )}
                        </span>
                        <span>
                            <strong>Data pagamento : </strong>
                            {business?.payment ? business?.payment?.paymentDate || '-' : '-'}
                        </span>
                    </div>

                    <div className="flex flex-wrap justify-around">
                        <span>
                            <strong>Risultato : </strong>
                            {business?.result
                                ? `${business?.result?.score}/${business?.result?.questionTotalPoints}`
                                : '-'}
                        </span>
                        <span>
                            <strong>Risultato in % : </strong>
                            {business?.result
                                ? `${Math.round(
                                      (business.result.score * 100) /
                                          business.result.questionTotalPoints
                                  )}%`
                                : '-'}
                        </span>
                        <span>
                            <strong>Documento : </strong>
                            {business?.result ? (
                                <span
                                    className="text-sky-500 cursor-pointer"
                                    onClick={() => downloadPdf(business.id)}
                                    aria-hidden="true">
                                    Download
                                </span>
                            ) : null}
                        </span>
                        <span>
                            <strong>Link per valutazione : </strong>
                            {business?.payment?.status === 'Completed' ? (
                                <span
                                    className="text-sky-500 cursor-pointer"
                                    onClick={copyLink}
                                    aria-hidden="true">
                                    Copia link
                                </span>
                            ) : null}
                        </span>
                        <span className="min-width-[80px]" />
                    </div>
                </section>
            </Card>

            {business?.surveys.length ? (
                <Card>
                    <QuestionListingComponent rows={getRows(business.surveys)} />
                </Card>
            ) : null}
        </BusinessDetails>
    );
}

export default Evaluation;
