import Storage from './services/common/storage-helper-services';

export const getCurrentYear = () => new Date().getFullYear();

export const convertSnakeCaseToCamelCase = (text) => {
    return text.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', '');
    });
};

export const convertCamelCaseToSnakeCase = (text) => {
    return text.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

const forEach = (array, iteratee) => {
    let index = -1;
    const { length } = array;
    // eslint-disable-next-line no-plusplus
    while (++index < length) {
        iteratee(array[index], index);
    }
    return array;
};

export const mapKeys = (target, iterator) => {
    if (target && typeof target === 'object') {
        const isArray = Array.isArray(target);
        const cloneTarget = isArray ? [] : {};
        if (isArray) {
            forEach(target, (value, index) => {
                cloneTarget[index] = mapKeys(value, iterator);
            });
        } else {
            forEach(Object.keys(target), (key) => {
                cloneTarget[iterator(key)] = mapKeys(target[key], iterator);
            });
        }
        return cloneTarget;
    }
    return target;
};

export const logoutProcess = () => {
    Storage.clear();
    window.location.href = '/admin/login';
};

export const getDevice = (width) => {
    return width > 900 ? 'desktop' : 'mobile';
};

export const getPaginateData = (response) => {
    let data = null;
    if (response.data.links) {
        const nextPage = response.data.nextPageUrl ? response.data.nextPageUrl.split('?')[1] : null;
        const previousPage = response.data.prevPageUrl
            ? response.data.prevPageUrl.split('?')[1]
            : null;
        data = {
            from: response.data.from,
            to: response.data.to,
            total: response.data.total,
            nextPage,
            previousPage
        };
    }
    return data;
};

export const getEnvironmentKeyValue = (key) => {
    const envKey =
        process.env.NODE_ENV === 'production' ? `REACT_APP_PROD_${key}` : `REACT_APP_DEV_${key}`;
    return process.env[envKey];
};
