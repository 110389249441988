import React from 'react';
import { useNavigate } from 'react-router-dom';

import UserBtn from '../component/UserBtn';
import UserLayout from '../layouts/UserLayout';

function TermsAndConditions() {
    const navigate = useNavigate();

    const closeBtnOnClickHandler = () => {
        navigate(-1);
    };

    return (
        <UserLayout title="TERMINI E" subtitle="DEFINIZIONI">
            <section className="pt-12">
                <p>
                    Ai fini del presente documento valgono i termini e le definizioni di cui alla
                    UNI EN ISO 9000 e i seguenti:
                </p>

                <p>
                    <strong>3.1 addetto/a: </strong>Persona occupata in un’unità giuridica, come
                    lavoratore/lavoratrice indipendente o dipendente (a tempo pieno, a tempo
                    parziale o con contratto di formazione lavoro), anche se temporaneamente assente
                    (per servizio, ferie, malattia, sospensione dal lavoro, Cassa integrazione
                    guadagni, ecc.). Comprende il/i titolare/i della/e impresa/e partecipante/i
                    direttamente alla gestione, i cooperatori (soci di cooperative che, come
                    corrispettivo della loro prestazione, percepiscono un compenso proporzionato
                    all’opera resa e una quota degli utili dell’impresa), i coadiuvanti familiari
                    (parenti o affini del titolare che prestano lavoro manuale senza una prefissata
                    retribuzione contrattuale), dirigenti, quadri, impiegati, operai e apprendisti.
                </p>

                <p>
                    <strong>3.2 benefit: </strong>Tutti i beni e servizi che un&apos;organizzazione
                    offre, in varie forme (es. buoni pasto, automobile, assicurazione sanitaria,
                    asili nido, voucher per attività sportive dei figli, facilitazioni d’acquisto
                    sui propri prodotti), al personale dipendente in aggiunta alla retribuzione.
                </p>

                <p>
                    <strong>3.3 budget: </strong>Programmazione delle attività di un’organizzazione
                    in vista del conseguimento di determinati obiettivi, con stanziamento della
                    somma che si decide di spendere.
                </p>

                <p>
                    <strong>3.4 caregiver: </strong>Chi si prende cura di un’altra persona.
                </p>

                <p>
                    <strong>3.5 check list: </strong>Lista dei requisiti della presente UNI/PdR che
                    occorre controllare e spuntare per verificare che le attività pianificate siano
                    state eseguite correttamente.
                </p>

                <p>
                    <strong>3.6 comitato guida: </strong>Comitato istituito dall’Alta Direzione per
                    l’efficace adozione e la continua ed efficace applicazione della Politica per la
                    Parità di Genere.
                </p>

                <p>
                    <strong>3.7 conciliazione vita-lavoro: </strong>Capacità di equilibrare la sfera
                    lavorativa e quella privata.
                </p>
                <p>
                    <strong>3.8 DEI: </strong>Diversità, Equità e Inclusione.
                </p>
                <p>
                    <strong>3.9 delega: </strong>Assegnazione dell&apos;esercizio di poteri e
                    funzioni e della capacità di agire relativamente a determinati voci di costo,
                    investimenti, compiti.
                </p>
                <p>
                    <strong>3.10 dipendente: </strong>Persona che svolge la propria attività
                    lavorativa in un’unità giuridica ed è iscritta nel libro paga
                    dell’organizzazione. Sono considerati tra i/le lavoratori/lavoratrici
                    dipendenti:
                    <ul>
                        <li>− le persone socie di cooperativa iscritte nel libro paga;</li>
                        <li>
                            − i/le dirigenti, i quadri, gli/le impiegati/e e gli/le operai/e, a
                            tempo pieno o parziale;
                        </li>
                        <li>− gli/le apprendisti/e;</li>
                        <li>− i/le lavoratori/lavoratrici a domicilio iscritti nel libro paga;</li>
                        <li>− i/le lavoratori/lavoratrici stagionali;</li>
                        <li>− i/le lavoratori/lavoratrici con contratto di formazione lavoro.</li>
                    </ul>
                </p>
                <p>
                    <strong>3.11 discriminazione: </strong>Assenza di pari opportunità e
                    trattamento, operata in seguito a un giudizio o a una classificazione.
                    <br />
                    NOTA Nella presente UNI/PdR si intende principalmente discriminazione di genere.
                </p>
                <p>
                    <strong>3.12 empowerment femminile: </strong>Processo attraverso cui una donna
                    acquisisce competenze, autonomia e potere che le permettono di compiere scelte
                    strategiche in ambito personale, sociale, politico ed economico e quindi di
                    avere il controllo sulla propria vita. Tale processo, sostenuto da politiche che
                    favoriscono la parità di genere, riconosce i bisogni e le esperienze specifiche
                    delle donne, permettendo loro di vivere relazioni paritarie e di esercitare il
                    diritto di accedere al mercato del lavoro e ai servizi pubblici rilevanti (es.
                    welfare, salute, istruzione).
                </p>
                <p>
                    <strong>3.13 formazione: </strong>Processo attraverso cui le persone
                    acquisiscono conoscenze e capacità, tecniche e manageriali, che abilitano la
                    piena partecipazione e contribuzione rispetto al proprio ruolo all’interno
                    dell’organizzazione.
                </p>
                <p>
                    <strong>3.14 gender diversity: </strong>Diversità di genere.
                </p>
                <p>
                    <strong>3.15 governance: </strong>Sistema di governo attraverso il quale
                    un&apos;organizzazione prende e attua le decisioni nel perseguimento dei suoi
                    obiettivi.
                </p>
                <p>
                    <strong>3.16 HR: </strong>Risorse Umane.
                </p>
                <p>
                    <strong>3.17 inclusione: </strong>Inserimento stabile e funzionale in un sistema
                    o gruppo per favorire pari opportunità e non discriminazione creando valore per
                    le persone, per le organizzazioni e il contesto sociale.
                </p>
                <p>
                    <strong>3.18 Key Performance Indicator (KPI): </strong>Indicatori chiave di
                    prestazione, utilizzati per il monitoraggio degli obiettivi stabiliti dalla
                    politica di parità di genere e descritti nel piano strategico.
                </p>
                <p>
                    <strong>3.19 molestia (molestia sessuale): </strong>Comportamento indesiderato
                    avente lo scopo o l’effetto di rendere un ambiente di lavoro offensivo,
                    umiliante o degradante.
                </p>
                <p>
                    <strong>3.20 parità di genere: </strong>Parità di genere tra sessi.
                </p>
                <p>
                    <strong>3.21 piano strategico: </strong>Documento formale nel quale
                    l&apos;organizzazione definisce gli obiettivi da perseguire, stabilisce risorse,
                    responsabilità, metodi e frequenze di monitoraggio.
                </p>
                <p>
                    <strong>3.22 politica di parità di genere: </strong>Documento formale nel quale
                    l&apos;organizzazione definisce il quadro generale all&apos;interno del quale
                    devono essere individuate le strategie e gli obiettivi riguardanti
                    l&apos;uguaglianza di genere.
                </p>
                <p>
                    <strong>3.23 promozione: </strong>Passaggio a una qualifica o livello
                    contrattuale o organizzativo superiore.
                </p>
                <p>
                    <strong>3.24 recruiting: </strong>Selezione del personale.
                </p>
                <p>
                    <strong>3.25 retribuzione: </strong>Importo della retribuzione complessiva
                    corrisposta, delle componenti accessorie del salario, anche collegate alle
                    premialità del risultato, dei bonus e di ogni altro beneficio riconosciuti al
                    personale, in base a quanto previsto dai contratti collettivi sottoscritti dalle
                    organizzazioni sindacali comparativamente più rappresentative a livello
                    nazionale per il settore di riferimento.
                    <br />
                    NOTA La retribuzione si compone di una parte fissa a cui possono aggiungersi una
                    parte variabile (monetaria e/o finanziaria) ed eventuali benefit (tipicamente
                    non monetari).
                </p>
                <p>
                    <strong>3.26 selezione: </strong>Processo volto al reperimento, alla scelta e
                    all&apos;inserimento nell&apos;organizzazione di nuove risorse.
                </p>
                <p>
                    <strong>3.27 smart working o lavoro agile o lavoro flessibile: </strong>Modalità
                    di esecuzione del rapporto di lavoro subordinato caratterizzato
                    dall&apos;assenza di vincoli orari o spaziali e un&apos;organizzazione per fasi,
                    cicli e obiettivi, stabilita mediante accordo tra dipendente e datore di lavoro;
                    una modalità che aiuta il lavoratore a conciliare i tempi di vita e lavoro e, al
                    contempo, favorire la crescita della sua produttività.
                    <br />
                    [Fonte: Definizione tratta dalla Legge n. 81/2017]
                </p>
                <p>
                    <strong>3.28 staff: </strong>Gruppo di persone addette a un particolare compito,
                    di solito sotto la guida di una persona responsabile che coordina.
                </p>
                <p>
                    <strong>3.29 stereotipi di genere: </strong>Insieme rigido di credenze condivise
                    e trasmesse socialmente, su quelli che sono e devono essere i comportamenti, il
                    ruolo, le occupazioni, i tratti, l’apparenza fisica di una persona, in relazione
                    alla sua appartenenza di genere.
                </p>
                <p>
                    <strong>3.30 sviluppo: </strong>Sistema integrato di attività tramite il quale
                    il personale migliora le proprie competenze e abilità e ne impara di nuove, in
                    modo da svolgere il lavoro in modo più efficace ed efficiente (formazione,
                    coaching, job rotation, ecc.).
                </p>
                <p>
                    <strong>3.31 talent development: </strong>Processo di sviluppo del talento del
                    personale e del suo potenziale.
                </p>
                <p>
                    <strong>3.32 target: </strong>Obiettivo che un’organizzazione si propone di
                    raggiungere, espresso in termini quantitativi.
                </p>
                <p>
                    <strong>3.33 telelavoro: </strong>Lavoro effettuato a distanza grazie
                    all’utilizzo di sistemi telematici di comunicazione.
                </p>
                <p>
                    <strong>3.34 valori: </strong>Elementi chiave della cultura di
                    un&apos;organizzazione che rafforzano l&apos;identità nell&apos;organizzazione
                    stessa, che indirizzano i comportamenti attesi dalle risorse e che e al tempo
                    stesso la contraddistinguono da tutte le altre organizzazioni.
                </p>
                <p>
                    <strong>3.35 vertice: </strong>Top Management o Alta Direzione (es. Country
                    Manager).
                </p>
                <p>
                    <strong>3.36 welfare aziendale: </strong>Insieme delle azioni volte a migliorare
                    il benessere dei/delle lavoratori/lavoratrici e delle loro famiglie con un
                    approccio di “corso di vita”. I/le lavoratori/lavoratrici sono consumatori di
                    servizi e prodotti di welfare aziendale e devono essere partecipi (soggetti
                    attivi) delle azioni decise. Il piano di welfare aziendale deve essere
                    integrativo e complementare con il welfare pubblico e coerente con il contesto
                    locale dell’organizzazione. Le somme, i beni, le prestazioni, le opere e i
                    servizi hanno finalità di rilevanza sociale e per questo sono escluse, in tutto
                    o in parte, da reddito di lavoro.
                </p>
                <p>
                    <strong>3.37 well-being: </strong>Benessere fisico, psicologico e sociale.
                </p>
                <p>
                    <strong>3.38 whistle-blowing: </strong>Denuncia, di solito anonima, presentata
                    dal personale di un’organizzazione alle autorità pubbliche, ai mezzi
                    d’informazione, a gruppi di interesse pubblico, di attività non etiche o
                    illecite commesse all’interno dell’organizzazione stessa. Termine utilizzato
                    anche per le denunce al Sistema interno di Responsabilità Sociale.
                </p>
                <UserBtn className="my-12 block mx-auto" onClick={closeBtnOnClickHandler}>
                    CHIUDI
                </UserBtn>
            </section>
        </UserLayout>
    );
}

export default TermsAndConditions;
