/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';

export const checkIsTokenValidForPayment = async (token) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.VALIDATE_TOKEN}/${token}`);
    return result;
};

export const updatePassword = async (payload) => {
    const result = await axiosInstance.put(`v1/user`, payload);
    return result;
};

export const sendNewPassword = async () => {
    const result = await axiosInstance.post(`v1/auth/user/send-new-password`);
    return result;
};
