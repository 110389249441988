/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../../component/Card';
import { getBusinessById } from '../../../helper/services/apis/business-services';
import BusinessDetails from '../../../component/Layout/BusinessDetails';

function Client() {
    const params = useParams();
    const [business, setBusiness] = useState(null);
    const deleteModalData = {
        title: 'Vuoi eliminare questo utente?',
        body: "Attenzione! Una volta eliminato l'utente non sarà più possibile recuperarlo. Continuare?"
    };

    const initBusiness = async () => {
        const result = await getBusinessById(params.id).catch((e) => {
            console.log(e);
            setBusiness(null);
        });

        if (result && result.ok) {
            setBusiness({ ...result.data });
        }
    };

    useEffect(() => {
        initBusiness();
    }, []);

    return (
        <BusinessDetails
            businessId={business?.id}
            deleteModalData={deleteModalData}
            businessName={business?.name}>
            <Card className="space-y-24 p-6 pb-24">
                <section className="space-y-12">
                    {/* title */}
                    <div className="flex justify-between pb-6 border-b">
                        <h2 className="text-2xl">
                            <strong>Contatti</strong>
                        </h2>
                    </div>

                    <div className="flex flex-wrap justify-around">
                        <span>
                            <strong>Ragione sociale : </strong>
                            {business?.name}
                        </span>
                        <span>
                            <strong>Codice Fiscale/PIVA : </strong>
                            {business?.vatNumber}
                        </span>
                        <span>
                            <strong>Sede legale : </strong>
                            {business?.registeredOffice}
                        </span>
                        <span>
                            <strong>Comune : </strong>
                            {business?.common}
                        </span>
                    </div>

                    <div className="flex flex-wrap justify-around">
                        <span>
                            <strong>Cap : </strong> {business?.postalCode}
                        </span>
                        <span>
                            <strong>Indirizzo : </strong>
                            {business?.address}
                        </span>
                        <span>
                            <strong>Email : </strong>
                            <a href={`mailto:${business?.email}`} className="text-sky-500">
                                {business?.email}
                            </a>
                        </span>
                        <span>
                            <strong>PEC : </strong>
                            <a href="mailto:" className="text-sky-500">
                                {business?.pec}
                            </a>
                        </span>
                    </div>

                    <div className="flex flex-wrap justify-around">
                        <span>
                            <strong>Telefono : </strong>
                            {business?.telephoneNumber}
                        </span>
                        <span>
                            <strong>Occupati alle dipendeze : </strong>
                            {business?.numberOfEmployees}
                        </span>
                        <span>
                            <strong>Settore : </strong>
                            {business?.businessCategory.title}
                        </span>
                        <span>
                            <strong>Codice Ateco : </strong>
                            {business?.businessCategory.code}
                        </span>
                    </div>

                    <div className="flex flex-wrap justify-around">
                        <span>
                            <strong>Nome referente : </strong>
                            {business?.contactName}
                        </span>
                        <span>
                            <strong>Telefono referente : </strong>
                            {business?.contactPhone}
                        </span>
                        <span>
                            <strong>Mail referente : </strong>
                            <a href="mailto:" className="text-sky-500">
                                {business?.contactEmail}
                            </a>
                        </span>
                        <span>
                            <strong>Gruppo : </strong>
                            {business?.group}
                        </span>
                    </div>

                    <div className="px-12">
                        <strong>Note :</strong> {business?.note}
                    </div>
                </section>

                <section className="space-y-12">
                    {/* title */}
                    <div className="pb-6 border-b">
                        <h2 className="text-2xl">
                            <strong>Acquistato</strong>
                        </h2>
                    </div>

                    <div className="flex flex-wrap justify-around">
                        <span>
                            <strong>Fascia : </strong>
                            {business?.plane?.name}
                        </span>
                        <span>
                            <strong>Pagamento : </strong>
                            {business?.payment
                                ? business?.payment?.paymentType === 'Card'
                                    ? 'Carta di credito'
                                    : 'Bonifico'
                                : '-'}
                        </span>
                        <span>
                            <strong>Stato pagamento : </strong>
                            {business?.payment
                                ? business?.payment?.status === 'Inprogress'
                                    ? 'In sospeso'
                                    : 'Avvenuto'
                                : '-'}
                        </span>
                        <span>
                            <strong>Data pagamento : </strong>
                            {business?.payment ? business?.payment?.paymentDate || '-' : '-'}
                        </span>
                    </div>
                </section>
            </Card>
        </BusinessDetails>
    );
}

export default Client;
