/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';

function DataTable({
    columns = [],
    rows = [],
    className = '',
    headerClassName = '',
    rowClassName = '',
    paginateData = null,
    handlePagination
}) {
    return (
        <>
            <table className={`table-auto ${className}`}>
                <thead>
                    <tr className={headerClassName}>
                        {columns.map((column, index) => (
                            <th key={`h-${index}`} className={`p-6 pb-0 ${column?.className}`}>
                                {column?.template}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {rows.map((row, index) => (
                        <tr key={`r-${index}`} className={rowClassName}>
                            {row.cells?.map((cell, cellIndex) => (
                                <td key={`c-${cellIndex}`} className={`p-6 ${cell?.className}`}>
                                    {cell?.template}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            {paginateData ? (
                <div className="flex flex-col items-left mt-4">
                    <span className="text-sm text-gray-700">
                        Mostra da{' '}
                        <span className="font-semibold text-gray-900">{paginateData?.from}</span> a{' '}
                        <span className="font-semibold text-gray-900">{paginateData?.to}</span> di{' '}
                        <span className="font-semibold text-gray-900">{paginateData?.total}</span>{' '}
                        Inserimenti
                    </span>
                    <div className="inline-flex mt-2 xs:mt-0">
                        <button
                            type="button"
                            onClick={() => handlePagination(paginateData.previousPage)}
                            disabled={!paginateData.previousPage}
                            className="inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                            <svg
                                aria-hidden="true"
                                className="mr-2 w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            Prev
                        </button>
                        <button
                            type="button"
                            onClick={() => handlePagination(paginateData.nextPage)}
                            disabled={!paginateData.nextPage}
                            className="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                            Next
                            <svg
                                aria-hidden="true"
                                className="ml-2 w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            ) : null}
        </>
    );
}

export default DataTable;
