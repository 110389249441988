/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DashboardPageTitle from '../DashboardPageTitle';
import LeftArrow from '../../assets/Icons/left-arrow.png';
import Bin from '../../assets/Icons/bin.png';
import ModalComponent from '../modal/ModalComponent';
import UserBtn from '../UserBtn';
import { deleteBusiness } from '../../helper/services/apis/business-services';

function BusinessDetails({ children, businessId, deleteModalData, businessName }) {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const goBackBtnClickHandler = () => {
        navigate(-1);
    };

    const confirmDeleteBtnClickHandler = async (id) => {
        try {
            const result = await deleteBusiness(id);
            if (result && result.ok) {
                setIsOpen(false);
                toast.success('Removed.');
                navigate(-1);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const openConfirmDeleteModel = () => {
        setIsOpen(true);
    };

    const cancelBtnOnClickHandler = () => {
        setIsOpen(false);
    };

    return (
        <>
            <ModalComponent isOpen={isOpen}>
                {/* Modal header  */}
                <div className="py-9 px-6 rounded-t border-b text-center">
                    <h1 className="text-4xl font-semibold text-primary-900">
                        {deleteModalData?.title}
                    </h1>
                </div>
                {/* Modal body  */}
                <div className="flex p-8 text-center">
                    <h4 className="text-2xl"> {deleteModalData?.body} </h4>
                </div>

                {/* Modal footer  */}
                <div className="py-9 px-6 rounded-t border-t text-center flex justify-between">
                    <UserBtn onClick={cancelBtnOnClickHandler}>Annulla</UserBtn>
                    <UserBtn onClick={() => confirmDeleteBtnClickHandler(businessId)}>
                        Elimina
                    </UserBtn>
                </div>
            </ModalComponent>
            <section className="space-y-6">
                <DashboardPageTitle className="flex w-full text-4xl justify-between">
                    <span onClick={goBackBtnClickHandler} aria-hidden="true">
                        <img
                            src={LeftArrow}
                            alt="Left Arrow"
                            className="inline-block h-9 cursor-pointer pr-4"
                        />
                        {businessName}
                    </span>

                    <span onClick={openConfirmDeleteModel} aria-hidden="true">
                        <img
                            src={Bin}
                            alt="Remove"
                            className="inline-block h-9 cursor-pointer pr-4"
                        />
                    </span>
                </DashboardPageTitle>

                {children}
            </section>
        </>
    );
}

export default BusinessDetails;
