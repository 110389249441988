/* eslint-disable react/prop-types */
import React from 'react';
import DataTable from './DataTable';

const HEADERS_DATA = [
    {
        className: '',
        template: <span>N°</span>
    },
    {
        className: '',
        template: <span>Descrizione</span>
    },
    {
        className: 'flex justify-end',
        template: <span>Risposta</span>
    }
];

function QuestionListingComponent({ rows = [] }) {
    return (
        <DataTable
            columns={HEADERS_DATA}
            rows={rows}
            className="w-full text-left text-base border-b border-t border-border"
            headerClassName="text-lg"
            rowClassName="border-b border-border"
        />
    );
}

export default QuestionListingComponent;
