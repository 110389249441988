import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserBtn from '../component/UserBtn';
import UserLayout from '../layouts/UserLayout';

function SaveSurvey() {
    const navigate = useNavigate();

    const goToHomeBtnOnClickHandler = () => {
        navigate('/');
    };

    return (
        <UserLayout title="" subtitle="WINNING WOMAN INSTITUTE">
            <section className="text-center text-2xl">
                <div className="flex flex-col space-y-12">
                    <p>
                        <strong className="text-4xl">Questionario sospeso!</strong>
                    </p>
                    <p className="text-4xl">
                        Le risposte già inserite verranno salvate e mostrate successivamente. Sarà
                        possibile terminare il questionario utilizzando il link che è stato inviato
                        per mail all’indirizzo inserito in anagrafica.
                    </p>
                </div>

                <UserBtn className="my-12 block mx-auto" onClick={goToHomeBtnOnClickHandler}>
                    TORNA ALLA HOME
                </UserBtn>
            </section>
        </UserLayout>
    );
}

export default SaveSurvey;
