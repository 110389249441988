import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';

export const createQuestion = async (payload) => {
    const result = await axiosInstance.post(`v1/${API_ENDPOINT.QUESTIONO}`, payload);
    return result;
};

export const updateQuestion = async (payload, questionId) => {
    const result = await axiosInstance.put(`v1/${API_ENDPOINT.QUESTIONO}/${questionId}`, payload);
    return result;
};

export const getQuestions = async (queryString) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.QUESTIONOS}?${queryString}`);
    return result;
};

export const getPlaneQuestions = async (planeId, categoryId) => {
    const result = await axiosInstance.get(
        `v1/${API_ENDPOINT.PLANE_QUESTIONS}?planeId=${planeId}&categoryId=${categoryId}`
    );
    return result;
};

export const deleteQuestion = async (questionId) => {
    const result = await axiosInstance.delete(`v1/${API_ENDPOINT.QUESTIONO}/${questionId}`);
    return result;
};
export const add = async () => {};
