/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Card from '../../../component/Card';
import DashboardPageTitle from '../../../component/DashboardPageTitle';
import UserBtn from '../../../component/UserBtn';
import { MESSAGES } from '../../../constants';
import { updateIsInMaintenanceStatus } from '../../../helper/services/apis/config-services';
import { updatePassword } from '../../../helper/services/apis/user-services';
import Storage from '../../../helper/services/common/storage-helper-services';
import { logoutProcess } from '../../../helper/utility';
import ConfigContext from '../../../store/ConfigContext';

function Setting() {
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm();

    const ctx = useContext(ConfigContext);

    const onSubmitHandler = async (data) => {
        try {
            const result = await updatePassword(data);
            if (result && result.ok) {
                toast.success(MESSAGES.SUCCESS);
                logoutProcess();
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const initUser = async () => {
        const user = JSON.parse(Storage.get('profile'));
        reset({
            email: user.email,
            password: '',
            newPassword: '',
            confirmPassword: ''
        });
    };

    const toggleOnChangeHandler = async () => {
        // setIsMaintenance((currentState) => !currentState);
        const status = !ctx.isInMaintenance;
        ctx.onChangeHandler(status);
        try {
            const result = await updateIsInMaintenanceStatus({ isInMaintenance: status });
            if (result && result.ok) {
                toast.success(MESSAGES.SUCCESS);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        initUser();
    }, []);

    return (
        <section className="space-y-6">
            <DashboardPageTitle className="text-4xl">Impostazioni</DashboardPageTitle>

            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Card className="h-full p-6 space-y-6">
                    <div className="w-1/2 px-6">
                        <label htmlFor="eamil"> Mail* </label>
                        <input
                            id="email"
                            type="text"
                            placeholder="Ragione sociale"
                            {...register('email', { required: true })}
                            disabled
                        />
                    </div>
                    <div
                        className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                            errors?.password ? 'error-input' : ''
                        }`}>
                        <label htmlFor="password"> Password* </label>
                        <input
                            id="password"
                            type="password"
                            placeholder="Password"
                            {...register('password', {
                                required: true
                            })}
                        />
                        {errors?.password?.type === 'required' && (
                            <span>Password è obbligatorio</span>
                        )}
                    </div>
                    <div
                        className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                            errors?.newPassword ? 'error-input' : ''
                        }`}>
                        <label htmlFor="newPassword">Nuova Password* </label>
                        <input
                            id="newPassword"
                            type="password"
                            placeholder="Nuova Password"
                            {...register('newPassword', { required: true })}
                        />
                        {errors?.newPassword?.type === 'required' && (
                            <span>Nuova Password è obbligatorio</span>
                        )}
                    </div>
                    <div
                        className={`w-1/2 px-6 my-5 form-controller-vertical-label text-left ${
                            errors?.confirmPassword ? 'error-input' : ''
                        }`}>
                        <label htmlFor="confirmPassword">Conferma Password* </label>
                        <input
                            id="confirmPassword"
                            type="password"
                            placeholder="Conferma Password"
                            {...register('confirmPassword', {
                                required: true,
                                validate: (val) => {
                                    if (watch('newPassword') !== val) {
                                        return 'Your passwords do no match';
                                    }
                                }
                            })}
                        />
                        {errors?.confirmPassword?.type === 'required' && (
                            <span>Conferma Password è obbligatorio</span>
                        )}
                        {errors?.confirmPassword?.type === 'validate' && (
                            <span>Le tue password non corrispondono</span>
                        )}
                    </div>
                </Card>
                <div className="flex flex-row-reverse w-full my-6">
                    <UserBtn type="submit">SALVA</UserBtn>
                </div>
            </form>

            <DashboardPageTitle className="text-4xl">Manutenzione</DashboardPageTitle>

            <Card className="p-6 space-y-6 flex justify-between items-center">
                Attiva/disattiva la manutenzione per nascondere/rendere visibile il software agli
                utenti.
                <label
                    htmlFor="green-toggle"
                    className="inline-flex relative mr-5 cursor-pointer mt-0">
                    <input
                        type="checkbox"
                        value=""
                        id="green-toggle"
                        className="sr-only peer"
                        checked={ctx.isInMaintenance}
                        onChange={toggleOnChangeHandler}
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600" />
                </label>
            </Card>
        </section>
    );
}

export default Setting;
