/* eslint-disable react/prop-types */
import React from 'react';

function ModalComponent({ isOpen = false, children }) {
    return (
        // Main modal
        <div
            id="defaultModal"
            tabIndex="-1"
            aria-hidden="true"
            className={`items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full ${
                isOpen ? 'flex justify-center bg-[#1b1b1baf]' : 'hidden'
            }`}>
            <div className="relative p-4 w-full max-w-2xl h-full max-h-[80vh] md:h-auto">
                {/* Modal content  */}
                <div className="relative bg-white rounded-lg shadow transition-all translate-y">
                    {children}
                    {/* Modal footer */}
                    {/* <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200">
                        <button
                            data-modal-toggle="defaultModal"
                            type="button"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            I accept
                        </button>
                        <button
                            data-modal-toggle="defaultModal"
                            type="button"
                            className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                            Decline
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default ModalComponent;
